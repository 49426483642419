:root {
  --ad_primary: #1475DF;
  --ad_title: #212426;
  --ad_menu: #4f4f4f;
  --ad_subtle: #b5c9da;
  --ad_numbers: #deeefb;
  --ad_card_alt: #f1f8ff;
  --ad_white: #ffffff;
  --ad_black: #020304;
  --ad_text: #020304;
}
.settings .navbar.fixed-top {
  padding-left: 16px;
}
.leaveadmin i {
  color: orange;
}

.settings p {
  font-size: 1.1rem;
}

.adminhead:before {
  content: "";
  position: absolute;
  z-index: 0;
  height: 800px;
  width: 68%;
  background: #f5f8fe;
  border-bottom-right-radius: 100px;
  left: 0;
  top: 0;
}

.sblearningbox {
  background: #f5f8fe;
  border: .5px solid #d8dfff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px;
  margin: 100px 0;
}

.sblearningbox .hero-title {
  font-size: 50px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 52px;
  color: #212426;
}
.sblearningbox .hero-title > span {
  color: #5f2bb3;
}
.sblearningbox .btn {
  font-size: 20px;
  padding: 9px 40px;
  border-radius: 7px;
  color: white;
  background-color: #912cab;
}

.settings h1, .settings h2 {
  color: var(--ad_title);
}

.settings .navbar, .settings h1, .settings h2, .settings h3, .settings h4, .settings h5, .settings h6 {
  color: var(--ad_title);
}
.settings h1, .settings h2, .settings h3, .settings h4, .settings h5, .settings h6 {
  font-weight: 400;
}
.settings p {
  font-weight: 300;
}
.rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content,
.rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:hover, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:hover, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:focus, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:focus, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:focus, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:active, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:active, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:active {
  color: var(--ad_menu);
}


/* hover menu  */
.rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:active,
.rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:focus,
.rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown-menu > li > .rs-dropdown-item-content:hover,
.rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:hover,
.rs-sidenav-inverse .rs-sidenav-nav .rs-dropdown .rs-dropdown-toggle:hover, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-nav-item-active > .rs-nav-item-content:hover, .rs-sidenav-collapse-out .rs-nav-item-active > .rs-nav-item-content:hover {
  color: var(--ad_primary);
  background: unset;
}

.nav-toggle .rs-nav-item {
  width: 100% !important;
  min-width: 56px;
}


/* .settings .btn-toggle-lg, .settings .switch{
  display: none;
  } */





/* Nav Rsuite */
.rs-sidebar.rs-sidebar-collapse {
  z-index: 1050;
}
/* full background */
.rs-navbar.rs-navbar-inverse, .rs-sidenav-inverse, .rs-sidebar.rs-sidebar-collapse {
  background-color: white !important;
}
/* Fixed sidebar even if expand is false - was .fixed-scroll.true */
.settings .fixed-scroll {
  position: fixed;
  overflow-y: auto;
  height: 100vh;
  /* background-color: var(--ad_white); */
  -ms-overflow-style: none;
  scrollbar-width: none; 
  border-right: 1px solid var(--ad_numbers);
}
/* collapse */
.rs-sidebar.rs-sidebar-collapse{
  background-color: var(--ad_white);
  border-right: 1px solid var(--ad_numbers);
  box-shadow: 15px 0px 30px 0px rgba(241,248,255,0.6);
}
.rs-sidenav-collapse-out .rs-nav-item-active > .rs-nav-item-content:hover {
  border-radius: 100%;
}
.rs-sidenav-collapse-out  > .rs-icon.rs-icon-setting {
  top: 18px !important;
}

.rs-sidebar.rs-sidebar-collapse {
  align-content: center;
  align-items: center;
}

.dark .rs-sidebar.rs-sidebar-collapse, .dark .settings .fixed-scroll {
  border-right: none;
}


.settings .fixed-scroll.true::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.rs-sidenav-collapse-in {
  width: 260px!important;
}
.admintop {
  height: 100px; }
@media (max-width: 1439px) {
  .admintop {
    height: 90px; } }
@media (max-width: 1199px) {
  .admintop {
    height: 80px;
    padding: 12px 0; } }
@media (max-width: 767px) {
  .admintop {
    height: 70px; } }


.scroll-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 999;
  cursor: pointer;
}

.settings .separator {
  border-bottom: 1px solid var(--ad_numbers);
}

.menu-default.settings {
  height: 100vh !important;
  background: var(--ad_white);
}

.innersettings {
  background: none;
  margin-bottom: 100px;
  padding:10px 30px;

}
.settings .rs-content {
  background: var(--ad_white);
  color: var(--ad_text);
}

.settings h1, .settings h2{
  color: var(--ad_title);
}
.settings .navbar {
  background: var(--ad_white);
  box-shadow: 0px 2px 3px rgba(241, 248, 255, 0.4);
  -webkit-box-shadow: 0px 2px 3px rgba(241, 248, 255, 0.4);
  -moz-box-shadow: 0px 2px 3px rgba(241, 248, 255, 0.4);
  border-bottom: 1px solid var(--ad_numbers);
  position: relative !important;
}
/* .settings .navbar .search {
  display: none;
} */




/* Cards */

.settings .card {
  background-color: var(--ad_white);
  border: 1px solid var(--ad_numbers);
  box-shadow: 0px 2px 3px 0px rgba(241,248,255,0.5);
}

.settings .card.highlight {
  background-color: var(--ad_card_alt);
}

.settings .rs-btn.rs-btn-default.rs-picker-toggle {
  background: var(--ad_white) !important;
  border: 1px solid var(--ad_primary);
}
.dark .settings .rs-btn.rs-btn-default.rs-picker-toggle {
  background: var(--darker) !important;
}

.card.card-highlight {
  color: var(--ad_white);
  box-shadow: 12px 12px 10px 0px rgba(20, 117, 223, 0.2);
  border: none;
  background: linear-gradient(135deg, rgba(20,117,223,0.5), var(--ad_primary));
}

.card.card-subtle {
  background: var(--ad_numbers);
  color: var(--ad_title);
  border: none;
  box-shadow: none;
}

.card-img-settings {
  width: 200px;
}


.card-subtle .card-title.card-number i {
  opacity: 0.3;
  object-fit: cover;
  font-size: 10vh;
  max-height: 15vh;
  color: var(--ad_primary);
}

.admin_primary {
  color: var(--ad_primary);
}



/* RS Side toggle Menu */

/* First Back to */
.rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content {
  /* background-color: var(--setblack); */
  border-top: 1px solid var(--ad_numbers);
}
/* last */
.rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content {
  color: var(--ad_title);
}
/* Others */
.rs-sidenav-collapse-in .rs-sidenav-nav .rs-nav-item-active > .rs-nav-item-content {
  background-color: var(--ad_white);
}
/* collapsed icons only */
.rs-sidenav-collapse-out .rs-nav-item-active > .rs-nav-item-content {
  background-color: unset;
  color: var(--ad_title);
}



/* Minify */
.rs-navbar.rs-navbar-inverse .rs-nav-item > .rs-nav-item-content {
  color: var(--ad_primary);
}
.rs-navbar.rs-navbar-inverse .rs-nav-item > .rs-nav-item-content:hover {
  color: var(--ad_subtle);
}

/* hover minify */
.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:hover, .rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item > .rs-nav-item-content:focus {
  background-color: unset;
  color: var(--ad_primary);
}


.rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content, .rs-sidenav-inverse.rs-sidenav-collapse-in .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover, .rs-sidenav-inverse.rs-sidenav-collapsing .rs-dropdown .rs-dropdown-menu-active ~ .rs-dropdown-toggle:hover,  .rs-sidenav-inverse.rs-sidenav-collapsing .rs-nav-item-active > .rs-nav-item-content:hover{
  background-color: transparent;
}

/* Dropdown */
.rs-sidenav-collapse-out .rs-dropdown-menu {
  min-width: 280px;
  color: white;
  padding: 0 20px;
  background-color: var(--ad_white);
  border: 1px solid var(--ad_numbers);
}
/* collapsed dropdown open */
.rs-dropdown-menu .rs-dropdown-item-content{
  color: var(--ad_title);
}
.rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:hover, .rs-dropdown-menu > .rs-dropdown-item > .rs-dropdown-item-content:focus {
  color: var(--ad_primary);
  background-color: var(--ad_white);
}

/* collapsed header title in card */
.rs-dropdown-header {
  border-bottom: 1px solid var(--ad_numbers);
  color: var(--ad_primary);
  font-weight: 500;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.rs-dropdown-header:before {
  display: inline-block;
  content: '';
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 5px;
  width: 5px;
  margin-right: 10px;
  background-color: var(--ad_black);
  vertical-align: middle;
}



.rs-sidenav.rs-sidenav-inverse.rs-sidenav-collapse-in {
  padding: 0 20px;
}


/* .rs-sidenav-collapse-in .rs-dropdown.rs-dropdown-placement-right-start {
  margin: 23px 0 !important;
} */
.rs-sidenav .rs-nav-item .rs-nav-item-content, .rs-sidenav .rs-dropdown .rs-dropdown-toggle, .rs-sidenav-nav>.rs-sidenav-item {
  font-size: 14px;
}
.rs-dropdown-item {
  margin: 0 !important;
}

.rs-sidenav.rs-sidenav-inverse.rs-sidenav-collapse-out ul li {
  margin: 20px 0;
}
.rs-nav-item {
  border-radius: 10px;
}
.rs-sidenav-collapse-out .rs-nav-item .rs-nav-item-content, .rs-sidenav-collapse-out .rs-dropdown .rs-dropdown-toggle {
  height: 56px;
}

/* Trick align icon mobile */
/* .rs-icon {
  min-width: 20px;
  text-align: center;
} */


/* last */
.rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active) > .rs-nav-item-content:hover {
  color: var(--ad_primary);
  background: unset;
}


.anchor {
  display: block;
  height: 110px;
  margin-top: -110px;
  visibility: hidden; 
}
.w-set {
  width: 150px;
  margin: 10px 10px;
}

.adsettings .container-fluid:nth-child(even) {
  padding-top: 50px;
}
.adsettings .container-fluid:nth-child(odd) {
  padding-top: 50px;
  background: var(--ad_card_alt);
}
.adsettings h2 {
  color: var(--ad_primary);
}


h1 .rs-icon {
  font-size: 1.75rem;
}


.numberCircle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid var(--ad_primary);
  color: var(--ad_primary);
  font-size: 14px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Logo */
.adminLogo img {
  height: 200px !important;
  width: 300px !important;
  object-fit: contain !important;
  margin: 20px !important;
}


.uploadPortalSSL .rs-uploader-trigger-btn {
  background: #00adbf;
  color: white;
}


.watermarkupload > div {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.watermarkupload button {
  background: #00adbf;
  color: white;
}
.watermarkupload .rs-uploader-file-item-title, .watermarkupload .rs-uploader-file-item-size {
  color: black !important;
  font-size: 16px;
}
.watermarkupload #watermark {
  width: 285px;
  height: 285px;
  object-fit: contain;
}


.logoextra {
  width: 160px;
  object-fit: contain;
  margin-right: 30px;
}

.settings .innersettings h1 i {
  font-size: 1rem;
  vertical-align: middle;
  margin-right: 10px;
  color: #007bff;
}

.service-item p {
  font-size: 16px;
}
.service-item .golearning {
  color: purple;
}
.service-item p.golearning:hover {
  opacity: 0.6;
}


.settingcard {
  background: #fafafa;
  padding: 35px 45px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-top: 40px;
  margin-bottom: 100px;
}
.settings .service-item {
  border-left: 1px solid #af5faf;
  border-radius: unset;
  box-shadow: none;
  padding: 0 0 0 20px;
  height: fit-content;
}



/* colors and for span */
.settitle {
  color: #007bff !important;
}
.setrequired {
  color: red;
}
.setoptional {
  color: #ff8f00;
}
.setinfo {
  color: #555;
  font-weight: 100;
}

.dark .settings .service-item, .dark .settings .pricing-box {
  background-color: white;
}

/* LDAP Upload file btn */
.upcertif {
  background: #00adbf !important;
  color: white !important;
  border: 1px solid #00adbf !important;
}
.existcertif {
  background: #1bb505 !important;
  color: white !important;
  border: 1px solid #1bb505 !important;
}
.upcertif:hover,.existcertif:hover {
  opacity: 0.7;
}

/* SEE TO DELETE */
.setuploadbtn [type="file"]::file-selector-button {
  border: 2px solid #007bff;
  padding: .2em .4em;
  border-radius: .2em;
  background-color: #007bff;
  transition: 1s;
  color: white;
}
.setuploadbtn [type="file"]::file-selector-button:hover {
  background-color: #51a1f7;
}

/* For Markdown */
.changemkd h2 {
  margin-top: 50px;
  color: #007bff;
}
.changemkd ul {
  margin-bottom: 50px;
}

/* Media Consent div */
.consentdiv {
  background-color: white;
  padding: 15px;
  box-shadow: 1px 2px 5px rgba(220,220,220,0.2);
}

.settingcard h1, .settingcard h2, .settingcard h3, .settingcard h4, .settingcard h5, .settingcard h6 {
  margin-bottom: 12px;
}
.settingcard p {
  margin-top: 8px;
}
.dark .settings .settitle {
  color: var(--darkmenubkg) !important;
}


/* vertical center cells table SIS */
.rs-table-cell-first .rs-table-cell-content, .rs-table-cell-content {
  display: flex;
  align-items: center;
}

.rs-table-cell.first {
  background: #ecf3f9;
}

.rs-table {
  border: 1px solid #eee;
}

.dark .rs-table {
  border-color: var(--darker);
}


.sb_lc_textarea h4 {
  margin-top: 10px;
  font-size: 16px;
}
.sb_lc_textarea textarea {
  resize: none;
  font-size: 14px;
  width: 240px;
  height: 40px;
  padding: calc(40px / 6) 10px;
  margin-top: 5px;
  margin-right: 20px;
}

/* Admin sidenav subtle */
.dark .rs-sidenav-subtle {
  background-color: var(--darker);
}
.dark .rs-nav-subtle.rs-nav-vertical .rs-nav-waterline {
  background: var(--dark);
}
.dark .rs-btn-subtle.rs-dropdown-toggle:hover {
  background: var(--dark);
}
.dark .rs-dropdown-expand a, .dark .rs-dropdown-collapse a {
  background: var(--darker);
}


.rs-sidenav-subtle {
  background-color: white;
}

.leaveadmin:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  border: 1px solid #1675e0;
  height: 100%;
}
/* .rs-sidebar {
  border-right: 1px solid #efefef;
} */


.sistable .rs-table-cell-content {
  padding: 0px 10px;
}


.dark .rs-picker-toggle {
  background: var(--darker);
}
.dark .rs-picker-popup {
  background: var(--darkblue);
}
.dark .rs-picker-popup .rs-picker-search-bar .rs-picker-search-bar-input {
  background: var(--dark);
  border-color: var(--dark);
  color: white;
}
.dark .rs-picker-popup .rs-picker-menu-group-title {
  color: #000;
  font-weight: 400;
}
.dark .rs-picker-popup .rs-picker-menu-group-title .rs-picker-menu-group-caret {
  color: black;
}
.dark .rs-picker-popup .rs-picker-select-menu-items .rs-picker-menu-group:not(:first-child) {
  border-color: var(--dark);
}
.dark .rs-picker-popup .rs-picker-select-menu-item.rs-picker-select-menu-item-active {
  background: var(--darker);
}
.dark .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  background: var(--dark);
}

.setuploadbtn p {
  padding: 1.5rem;
}

.settingcard .rs-radio-group>.rs-radio {
  margin-right: 25px;
}

.rs-table-cell-content {
  padding: 0px 10px;
}

.dark .rs-tag-icon-close.rs-btn-close svg {
  fill: red;
}

.dark .settings .breadcrumb-item {
  color: #ccc;
}

.admin_primary > svg {
  margin-right: 10px;
}
