@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: url("./assets/styles/Poppins/Poppins-ExtraLight.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url("./assets/styles/Poppins/Poppins-Light.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url("./assets/styles/Poppins/Poppins-Regular.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url("./assets/styles/Poppins/Poppins-Medium.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url("./assets/styles/Poppins/Poppins-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url("./assets/styles/Poppins/Poppins-Bold.ttf") format('truetype');
}

:root {
  --black: #1e1e24;
  --dark: #27293d;
  --darker: #070b24;
  --dark: #151933;

  --grey: #fbfbfb;
  --white: #fff;
  --blue: #1d8cf8;
  --darkblue: #3358f4;

  --darkmenubkg: #4a87fa;
  --darkmenulink: #271959;
  --darkmenuhover: #0a0a0b;

}

/* 01.Base */
html {
  width: 100%;
  height: 100%;
}

body,
#root,
.rounded {
  height: 100%;
}

body {
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  color: #29344a;
  font-weight: 300;
  background: #f6f7fb;
  position: relative;
  overflow-x: hidden;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

a {
  color: #29344a;
  transition: color 200ms;
  outline: initial !important;
  cursor: pointer;
}

a:hover,
a:active {
  text-decoration: initial;
  color: #00365a;
}

a:focus {
  color: unset;
}

a:active,
a:focus,
a:hover {
  text-decoration: none;
}

img {
  image-rendering: -webkit-optimize-contrast;
}

body main .container {
  height: 100%;
}

.fixed-background {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.rounded {
  border-radius: 50px !important;
}

/* incorrect Login */
.incorrect-login {
  color: red !important;
  border-color: red !important;
}

/* 16.Main*/
main {
  margin-left: 410px;
  margin-top: 145px;
  margin-right: 60px;
  margin-bottom: 40px;
}

main.default-transition {
  transition: margin-left 300ms;
}

main .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

@media (max-width: 1439px) {
  main {
    margin-left: 390px;
    margin-right: 50px;
    margin-top: 130px;
    margin-bottom: 30px;
  }
}

@media (max-width: 1199px) {
  main {
    margin-left: 370px;
    margin-right: 40px;
    margin-top: 110px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  main {
    margin-left: 15px !important;
    margin-right: 15px !important;
    margin-top: 80px !important;
    margin-bottom: 0;
  }
}

@media (max-width: 575px) {
  main {
    margin-bottom: 0;
  }
}

.innerpart {
  padding-top: 64px;
  margin-left: 80px;
  width: auto;
}


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: 1.3;
  font-weight: 400;
}

h1 {
  font-size: 1.55rem;
  padding-bottom: 10px;
  display: inline-block;
  color: #29344a;
}

@media (max-width: 767px) {
  h1 {
    font-size: 1.3rem;
    padding-top: 0.25rem;
    margin-bottom: 0;
  }
}

h2 {
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  h2 {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;
}

@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;
}

@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;
}

@media (max-width: 767px) {
  h4 {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;
}

@media (max-width: 767px) {
  h5 {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 1rem;
}

@media (max-width: 767px) {
  h6 {
    font-size: 0.85rem;
  }
}

button {
  color: #3a3a3a;
  outline: initial !important;
}


p {
  font-size: 0.9rem;
  line-height: 1.2rem;
  margin-bottom: 0 !important;
}

::selection {
  background: #c8d2dc;
}

::-moz-selection {
  background: #c8d2dc;
}


/* Logos */
.navbar-logo .logo img {
  width: 120px;
  height: auto;
}

.logolight,
.dark .logodark {
  display: none;
}

.dark .logolight {
  display: block;
}

/* logo administration */
.logosadmin,
.settings .logosgallery,
.settings .logoadmindark,
.dark .settings .logoadmin {
  display: none;
}

.settings .logosadmin,
.dark .settings .logoadmindark {
  display: block;
}

/* RS Spinner */
.rs-loader-spin:before {
  border: 3px solid rgba(75, 157, 230, 0.5) !important;
}

.rs-loader-spin:after {
  border-top-color: #164895 !important;
}


/* ------------------------------------------- */
/* Login page */
/* ------------------------------------------- */

body .loginpage main {
  margin: 0 !important;
  height: 100%;
}

.logo-single {
  width: 180px;
  height: 60px;
  background: url("./assets/logos/schoolbench.png") no-repeat;
  background-size: contain;
}

.rounded .auth-card .image-side {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.auth-card .image-side {
  width: 40%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 15px 0 15px 15px;
}

.dark-overlay {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #111b, #222);
  opacity: .1;
}


.auth-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
}

.auth-card .image-side .h3 {
  line-height: 0.8rem;
}

.auth-card .form-side {
  width: 60%;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 80px;
  padding-bottom: 10px;
}

@media (max-width: 991px) {
  .auth-card {
    flex-direction: column;
  }

  .auth-card .image-side {
    width: 100%;
    padding: 160px;
  }

  .auth-card .form-side {
    width: 100%;
    padding: 60px;
  }
}

@media (max-width: 767px) {
  .auth-card p.h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 575px) {
  .auth-card {
    flex-direction: column;
  }

  .auth-card .image-side {
    padding: 130px;
  }

  .auth-card .form-side {
    padding: 35px 30px;
  }

  .auth-card .logo-single {
    margin-bottom: 20px;
  }

  .auth-card p.h2 {
    font-size: 1.4rem;
  }
}

.index-card .card-body {
  padding: 80px;
}

@media (max-width: 991px) {
  .index-card .card-body {
    padding: 60px;
  }
}

@media (max-width: 575px) {
  .index-card .card-body {
    padding: 35px 30px;
  }
}

.loginpage .has-float-label label::after,
.loginpage .has-float-label>span::after {
  background: #fff;
}

/* Loader */
.rs-loader-spin:before {
  border: 3px solid rgba(75, 157, 230, 0.5) !important;
}

.rs-loader-spin:after {
  border-top-color: #164895 !important;
}

.innerpart.container-fluid {
  padding-right: 22px;
  min-height: calc(100vh - 52px);
}

#darkMode {
  background: transparent;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-150 {
  margin-bottom: 150px;
}

/* Menu */
.menu .main-menu ul .sideMenu {
  position: relative;
  display: table;
  width: 100%;
}

.menu .main-menu ul .sideMenu span {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  color: #29344a;
  transition: all 300ms;
}


.menu .main-menu a:hover span {
  color: #1d8cf8;
}

.menu .main-menu ul .active::before {
  content: " ";
  background: #3498ff;
  color: white;
  position: absolute;
  width: 4px;
  height: 44px;
  left: 0;
  display: table-cell;
  top: 2px;
  border-radius: 0 2px 2px 0;
}

.sideComingSoon {
  opacity: 0.4;
}

.scrollTop {
  cursor: pointer;
  animation-name: fadeInOpacitySC;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}

@keyframes fadeInOpacitySC {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.menu .main-menu ul .scrollTop span {
  color: #1d8cf8;
}

.mbutton {
  margin: 0 .5rem;
}

.mbutton i {
  vertical-align: initial;
  display: inline;
  margin-right: 6px;
}

.ml-svg {
  margin-left: 6px;
}

.rs-picker-toggle-wrapper {
  z-index: 0;
  position: relative;
}

.background .mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.scroll {
  margin-right: -15px;
  padding-right: 15px;
  position: relative;
  height: 100%;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.scrollable {
  position: relative;
  height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.scrollable::-webkit-scrollbar {
  display: none;
}

.list-item-heading {
  font-size: 1rem;
  overflow-wrap: normal;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.min-width-zero {
  min-width: 0;
}

.lead {
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 2rem;
}

@media (max-width: 767px) {
  .lead {
    font-size: 1.1rem;
    line-height: 1.6rem;
  }
}

.mb-5,
.my-5 {
  margin-bottom: 2rem !important;
}

.separator {
  border-bottom: 1px solid #efefef;
}

@media (max-width: 991px) {

  .separator.mb-5,
  .separator-tabs.mb-5 {
    margin-bottom: 1rem !important;
  }
}

@media (max-width: 575px) {
  .float-none-xs {
    float: initial !important;
  }
}

ul li {
  margin-bottom: 5px;
}

ul li:last-of-type {
  margin-bottom: 0;
}

.white,
.text-white {
  color: #fff !important;
}

.text-one {
  font-size: 0.9rem !important;
}

.text-small {
  font-size: 0.76rem !important;
  line-height: 0.9rem !important;
}

.text-xxsmall {
  font-size: 0.65rem !important;
}

.text-default {
  color: #3a3a3a !important;
}

.text-muted {
  color: #3e3e3e !important;
}

.text-semi-muted {
  color: #8f8f8f !important;
}

.border-radius {
  border-radius: 0.1rem;
}

/* 03.Widths */
.w-10 {
  width: 10% !important;
}

.w-90 {
  width: 90% !important;
}

.w-12 {
  width: 12% !important;
}

.w-88 {
  width: 88% !important;
}

.w-15 {
  width: 15% !important;
}

.w-85 {
  width: 85% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

@media (max-width: 767px) {
  .w-xs-100 {
    width: 100% !important;
  }
}

@media (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

/* Badge */
.badge {
  font-size: 74%;
}

.badge.badge-top-left {
  top: 10px;
  left: -10px;
  font-weight: 400;
}

.badge.badge-top-right {
  top: 4px;
  right: 3px;
}

.badge.badge-top-right button {
  border-radius: 100%;
  border: none;
}

.badge.badge-center {
  top: 40%;
  left: 41.5%;
}

/* 06.Breadcrumb */
@media (max-width: 991px) {
  .breadcrumb-container .breadcrumb {
    padding: 0;
  }
}

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "|";
}

/* Top Navbar */
.navbar {
  background: white;
  height: 52px;
  padding-left: 94px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .06);
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, .06);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, .06);
}


/* Width Admin mobile */
@media (max-width: 571px) {
  .rs-container {
    width: calc(100% - 30px);
  }
}

/* Menu Sidebar */
.menu {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 100px;
  z-index: 1031;
  height: 100%;
  transition: border-radius 300ms;
}

.menu .main-menu {
  width: 74px;
  height: 100%;
  background: white;
  z-index: 30;
  position: fixed;
  padding-top: 10px;
  padding-bottom: 10px;
  left: 0;
  top: 0;
  overflow: hidden;
  border-right: 1px solid #EFF2F7;
}

.menu .main-menu .scroll {
  padding-right: unset;
  margin-right: unset;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
}

.menu .main-menu .scroll .ps__thumb-y {
  right: 0;
}

.menu .main-menu.default-transition {
  transition: transform 300ms, border-radius 300ms;
}

.menu .main-menu ul {
  margin-bottom: initial;
}

.menu .main-menu ul li {
  position: relative;
}

.menu .main-menu ul li span {
  text-align: center;
  padding: 0 10px;
  line-height: 14px;
}

.menu .main-menu ul li a {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #3a3a3a;
  transition: color 300ms;
  transition: background 300ms;
}

.menu .main-menu ul li a:hover,
.menu .main-menu ul li a:focus {
  color: #00365a;
}

.menu .main-menu ul li i {
  font-size: 32px;
  line-height: 42px;
}

.menu .main-menu ul li.active a {
  color: #00365a;
}

.menu .main-menu ul li.active:after {
  content: " ";
  background: #00365a;
  color: white;
  border-radius: 10px;
  position: absolute;
  width: 6px;
  height: 90px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}

.menu .main-menu ul li:last-of-type a {
  border-bottom: initial;
}


@media (max-width: 767px) {
  .menu .main-menu ul li i {
    font-size: 26px;
    line-height: 30px;
  }

  .menu .main-menu ul li a {
    height: 90px;
  }

  .menu .main-menu ul li.active:after {
    width: 3px;
    height: 60px;
  }
}

@media (max-width: 1439px) {
  .menu {
    padding-top: 90px;
    height: calc(100% - 90px);
  }
}

@media (max-width: 1199px) {
  .menu {
    padding-top: 80px;
    height: calc(100% - 80px);
  }
}

@media (max-width: 767px) {
  .menu {
    padding-top: 70px;
    height: calc(100% - 70px);
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  }
}



/* Events Calendar */
.app-menu {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 280px;
  height: 100%;
  float: right;
  background: white;
  transition: transform 300ms;
  position: fixed;
  right: 0;
  top: 100px;
  height: calc(100% - 100px);
}

@media (max-width: 1439px) {
  .app-menu {
    top: 90px;
    height: calc(100% - 90px);
  }
}

@media (max-width: 1199px) {
  .app-menu {
    top: 80px;
    transform: translateX(280px);
    height: calc(100% - 80px);
  }
}

@media (max-width: 767px) {
  .app-menu {
    top: 70px;
    height: calc(100% - 70px);
  }
}

.app-menu .scroll {
  height: 100%;
}

.app-menu .scroll .ps__thumb-y {
  right: 0;
}

.app-menu.shown {
  transform: translateX(0);
}

.app-menu .app-menu-button {
  position: absolute;
  left: -28px;
  background: white;
  top: 45px;
  padding: 12px 8px 12px 5px;
  border-radius: 0.2rem;
  color: #8f8f8f;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
  font-size: 15px;
  line-height: 0;
}

.app-menu .app-menu-button {
  font-size: 18px;
  color: white !important;
}

@media (max-width: 1439px) {
  .app-menu .app-menu-button {
    top: 36px;
  }
}

@media (max-width: 1199px) {
  .app-menu .app-menu-button {
    top: 27px;
  }
}

@media (max-width: 767px) {
  .app-menu .app-menu-button {
    top: 13px;
  }
}

.app-menu ul li {
  margin-bottom: 5px;
}

.app-menu ul li a {
  font-size: 13px;
  display: block;
  padding: 3px 0;
}

.app-menu ul li a:hover i {
  color: #00365a;
}

.app-menu ul li i {
  font-size: 1.2em;
  margin-right: 10px;
  color: #8f8f8f;
  vertical-align: unset;
}

@media (max-width: 767px) {
  .app-menu ul li i {
    font-size: 20px;
  }
}

.app-menu ul li.active i,
.app-menu ul li.active a {
  color: #00365a;
}


/* Cards */
.card {
  border: initial;
  background: white;
  border-radius: 15px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.04);
}

.card .card-header .card-icon {
  right: 5px;
  top: 5px;
}

.card .card-header .card-icon i {
  font-size: 12px;
  color: #d7d7d7;
}

.card .card-header .handle {
  cursor: default;
}

.card .card-body {
  padding: 1.75rem;
}

@media (max-width: 575px) {
  .card .card-body {
    padding: 1.5rem;
  }
}

.card .card-body.sm {
  padding: 1.25rem 1.75rem;
}

.card .card-title {
  margin-bottom: 2rem;
}

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset;
}

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.card-img-top {
  width: 100%;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  padding: 15px 15px 0 15px;
}

.card-top-buttons {
  padding: 1rem;
  right: 0;
  top: 0;
}

@media (max-width: 575px) {
  .card-top-buttons {
    padding: 0.35rem;
  }
}

.card-top-buttons-left {
  padding: 1rem;
  left: 0;
  top: 0;
}

@media (max-width: 575px) {
  .card-top-buttons-left {
    padding: 0.35rem;
  }
}

.img-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
}

.img_student {
  width: 55px;
  height: 55px;
  object-fit: cover;
}

.list-thumbnail {
  border-radius: 0.1rem;
  padding: 0;
  border: initial;
  height: 100%;
  width: 140px;
  object-fit: cover;
}

.card-profiles {
  height: 160px;
  width: 140px;
  border-radius: 20px 0 15px;
  object-fit: cover;
}

/* Events list number photos in circle */
.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: #00365a;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #fff;
}

@media (max-width: 991px) {
  .list-thumbnail-letters {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 575px) {
  .list-thumbnail-letters {
    width: 70px;
    height: 70px;
  }
}

.list-thumbnail-letters.small {
  width: 60px;
  height: 60px;
  font-size: 1rem;
}

@media (max-width: 991px) {
  .list-thumbnail-letters.small {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 575px) {
  .list-thumbnail-letters.small {
    width: 50px;
    height: 50px;
  }
}


/* 22.Forms */
.form-check-label,
.custom-control-label {
  line-height: 1.5rem;
  padding-top: 1px;
}

.form-control,
.bootstrap-tagsinput {
  border-radius: 0.1rem;
  outline: initial !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.5rem 0.75rem;
  line-height: 1;
  border: 1px solid #d7d7d7;
  background: white;
  color: #3a3a3a;
  border-color: #d7d7d7;
  height: calc(2em + 0.8rem);
}

select {
  outline: initial !important;
  box-shadow: initial !important;
}

textarea.form-control {
  line-height: 1.5;
}

@keyframes autofill {
  to {
    color: #3a3a3a;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #3a3a3a !important;
}

.form-control:focus {
  background: white;
  color: #3a3a3a;
}

.form-control:focus,
.bootstrap-tagsinput.focus {
  border-color: rgba(0, 54, 90, 0.6);
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  background-image: initial;
}

.custom-control-input:disabled~.custom-control-label::before {
  background-color: rgba(58, 58, 58, 0.25);
}

.custom-control-input:active~.custom-control-label::before {
  background-color: transparent;
}

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23FFFFFF' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23FFFFFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23FFFFFF'/%3E%3C/svg%3E");
}

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important;
}

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1;
  pointer-events: none;
}

.custom-control-label::before {
  border: 1px solid #909090 !important;
  background: initial !important;
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  background-color: #00365a !important;
}

.custom-control-input:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #00365a !important;
  box-shadow: initial !important;
  border: 1px solid #00365a !important;
}

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important;
}

.custom-control-label::before {
  top: 0.25rem;
}

.custom-control-label::after {
  top: 0.25rem;
}

.btn.rotate-icon-click i {
  transition: transform 0.5s;
}

.btn.rotate i {
  transform: rotate(180deg);
}

.btn .custom-control-label::before {
  border: 1px solid #fff !important;
  background: transparent !important;
}

.btn .custom-control-input:checked~.custom-control-label::before {
  border: 1px solid #fff !important;
  background: transparent !important;
}


/* Drapzone Uploadfn */
.dropzone {
  min-height: 115px !important;
  border: 1px solid #d7d7d7 !important;
  background: white !important;
  padding: 10px 10px !important;
  border-radius: 0.1rem !important;
  color: #3a3a3a !important;
  height: auto;
}

.dropzone .img-thumbnail {
  height: 58px !important;
  width: 100% !important;
  object-fit: cover !important;
}

/* switch class in navtop */
.custom-switch {
  padding-left: initial;
}

.custom-switch .custom-switch-input+.custom-switch-btn {
  background: #d7d7d7;
}

.custom-switch.custom-switch-primary .custom-switch-input:checked+.custom-switch-btn {
  background: #00365a;
  border: 1px solid #00365a;
}

.custom-switch.custom-switch-secondary .custom-switch-input:checked+.custom-switch-btn {
  background: #184f90;
  border: 1px solid #184f90;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input+.custom-switch-btn {
  border: 1px solid #d7d7d7;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn {
  background: white;
  border: 1px solid #00365a;
}

.custom-switch.custom-switch-primary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
  background: #00365a;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input+.custom-switch-btn {
  border: 1px solid #d7d7d7;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn {
  background: white;
  border: 1px solid #184f90;
}

.custom-switch.custom-switch-secondary-inverse .custom-switch-input:checked+.custom-switch-btn:after {
  background: #184f90;
}

.input-group-text {
  border-radius: 0.1rem;
}

.custom-switch .custom-switch-input+.custom-switch-btn:after {
  background: white;
}

.custom-switch .custom-switch-input+.custom-switch-btn {
  border-color: #d7d7d7;
}

/* 27.Progress */
.progress {
  height: 3px;
  background-color: #f3f3f3;
}

.progress-bar {
  background-color: #00365a;
}

.progressbar-text {
  position: absolute;
  left: 0;
  width: 50px;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  color: #3a3a3a !important;
}

.progress-bar-circle {
  width: 54px;
  height: 54px;
}

.progress-bar-circle svg path:first-of-type {
  stroke: #d7d7d7;
}

.progress-bar-circle svg path:last-of-type {
  stroke: #00365a;
}

.progress-bar-circle.progress-bar-banner svg path:first-of-type {
  stroke: #4d728c;
}

.progress-bar-circle.progress-bar-banner svg path:last-of-type {
  stroke: #fff;
}

.rs-uploader.rs-uploader-text.rs-uploader-draggable {
  justify-content: center;
  display: flex;
  align-items: center;
}

.addprimg .rs-uploader-trigger-btn {
  height: 200px;
  width: 200px;
  align-items: center;
  display: grid !important;
  text-align: center;
}

.dark .addprimg .rs-uploader-trigger-btn {
  background-color: var(--darker);
}

/* 35.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center !important;
  float: left;
  height: 100px;
}

.simple-line-icons .glyph .glyph-icon,
.simple-line-icons .glyph .fa,
.mind-icons .glyph .glyph-icon,
.mind-icons .glyph .fa {
  font-size: 32px;
  text-align: center !important;
}

.simple-line-icons .glyph .author-name,
.mind-icons .glyph .author-name {
  display: none;
}

.simple-line-icons .glyph .class-name,
.mind-icons .glyph .class-name {
  font-size: 0.76rem;
  color: #909090 !important;
  text-align: center !important;
}

@media (max-width: 1199px) {

  .simple-line-icons .glyph,
  .mind-icons .glyph {
    width: 16.66%;
  }
}

@media (max-width: 991px) {

  .simple-line-icons .glyph,
  .mind-icons .glyph {
    width: 20%;
  }
}

@media (max-width: 767px) {

  .simple-line-icons .glyph,
  .mind-icons .glyph {
    width: 25%;
  }
}

@media (max-width: 575px) {

  .simple-line-icons .glyph,
  .mind-icons .glyph {
    width: 50%;
  }
}

/* 38.Context Menu */
.context-menu-list {
  box-shadow: initial;
  border-radius: 0.1rem;
  background: white;
  border-color: rgba(58, 58, 58, 0.15);
  padding: 0.5rem 0;
}

.context-menu-item {
  padding: 0.75rem 1.5rem;
  background: white;
  color: #3a3a3a;
}

.context-menu-item.context-menu-hover {
  color: #3a3a3a;
  text-decoration: none;
  background-color: #f8f8f8;
}

.context-menu-item span {
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  margin-left: 0.5rem;
  display: inline-block;
}

.list .card.context-menu-active,
.list .card.active {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1);
}

/* 41.Tables */
.table {
  color: #3a3a3a;
}

.table th,
.table td {
  border-color: #f3f3f3 !important;
}

/* Profile image rounded */
.listing-card-container .listing-heading {
  height: 45px;
  overflow: hidden;
}

.listing-card-container .listing-desc {
  height: 60px;
  overflow: hidden;
  line-height: 1.4;
}

@media (max-width: 1199px) {
  .listing-card-container .listing-desc {
    height: 60px;
  }
}

.student-card.rounded-circle {
  padding: 0;
  height: 120px;
  object-fit: cover;
  width: 120px;
}

.top-right-button-container {
  float: right;
  position: relative;
}

@media (max-width: 575px) {
  .top-right-button-container {
    float: initial;
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 575px) {
  .top-right-button-container .top-right-button {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}


/* Button basic */
button {
  color: #3a3a3a;
  outline: initial !important;
}

.btn {
  border-radius: 50px;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  font-size: 0.8rem;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear;
}

.btn-shadow:hover,
.btn-shadow:focus {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important;
}

.btn-lg,
.btn-group-lg>.btn,
.btn-group-sm>.btn {
  border-radius: 8px;
}

.btn.default {
  border-radius: 0.1rem;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #001e31;
  border-color: #001e31;
}

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px;
}

.btn.btn-outline-white {
  color: #999;
  border: 1px solid #999;
  background-color: initial;
}

.btn.btn-outline-white:hover {
  color: #00365a;
  border-color: #00365a;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #00365a;
  border-color: #00365a;
  color: white;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #184f90;
  border-color: #184f90;
  color: white;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  background-color: #00111d;
  border-color: #00111d;
  color: #fff;
}

.btn-secondary {
  background-color: #184f90;
  border-color: #184f90;
  color: #fff;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #123c6d;
  border-color: #123c6d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  background-color: #0f325c;
  border-color: #0f325c;
  color: #fff;
}

/* Footer */
body.no-footer {
  padding-bottom: initial;
}








.app-menu {
  width: 480px;
}

@media (max-width: 1199px) {
  .app-menu {
    transform: translateX(480px);
  }
}

#app-container.right-menu .innerpart {
  width: calc(100% - 600px);
}

.add-event-btn {
  display: none;
}

@media (max-width: 1199px) {
  #app-container.right-menu .innerpart {
    width: auto;
  }

  .add-event-btn {
    display: block;
  }
}


.app-menu .open-eventbar {
  display: block;
}

.app-menu .close-eventbar {
  display: none;
}

.app-menu.box.shown .open-eventbar {
  display: none;
}

.app-menu.box.shown .close-eventbar {
  display: block;
}

.add-student .drop-area {
  min-height: 250px !important;
}

.add-student .drop-area .card-body {
  height: auto;
}


.rs-btn-toggle.privatetoggle {
  background-color: #f93636;
}

.rs-btn-toggle.privatetoggle.rs-btn-toggle-checked {
  background-color: #4caf50;
}


/* Toggle Dark Mode and animation */
.switch {
  position: relative;
}

.switch,
#darkMode,
.custom-switch {
  width: 30px;
}

.switch input {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 100;
  cursor: pointer;
}

.switch label {
  height: 14px;
  width: 30px;
  background-color: #ecf0f3;
  border-radius: 100px;
  display: block;
  box-shadow: -6px -2px 6px 0px #fff, 6px 2px 12px 0px #d1d9e6, 2px 2px 2px 0px #d1d9e6 inset, -2px -2px 2px 0px #fff inset;
  margin-bottom: 0px;
}

.switch label .bulb {
  width: 16px;
  background-color: #4a426b;
  border-radius: 50%;
  position: relative;
  top: 7px;
  left: 0px;
  display: block;
  transition: .4s;
  box-shadow: inset 0 0 1px 3px #4a426b, inset 0 0 6px 8px #423963, 0 20px 30px -10px rgba(0, 0, 0, 0.4);
}

.switch label .bulb .bulb-center {
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  background-color: #ecf0f3;
  border-radius: 50%;
  left: 50%;
  transition: .4s;
  transform: translate(-50%, -50%);
  box-shadow: inset 2px 0 4px 0px #ecf0f3, inset 0 0 1px 6px white, 0 1px 6px 0px rgba(0, 0, 0, 0.25);
}

.dark .switch label .bulb .bulb-center {
  left: 0
}

.switch label .bulb .bulb-center:after {
  content: "";
  display: block;
  height: 4px;
  width: 4px;
  background-color: #d1d9e6;
  border-radius: 50%;
  position: absolute;
  transition: .3s;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 4px #d1d9e6;
}







.reflections {
  height: 100%;
  width: 100%;
  display: block;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  z-index: 90;
  perspective: 10px;
}

.reflections span {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background-image: linear-gradient(-135deg, transparent 10%, rgba(255, 255, 255, 0.3));
  position: absolute;
  left: -40px;
  bottom: -45px;
}

.reflections span:after {
  content: "";
  display: block;
  height: 35px;
  width: 20px;
  position: absolute;
  top: -36px;
  right: -40px;
  border-radius: 50%;
  box-shadow: 4px -2px 0 -3px rgba(255, 255, 255, 0.4);
  filter: blur(1px);
  transform: rotate(-10deg);
}

.reflections:after {
  content: "";
  display: block;
  height: 80px;
  width: 50px;
  background-image: linear-gradient(80deg, rgba(255, 255, 255, 0.05) 45%, rgba(255, 255, 255, 0.5));
  border-radius: 10% 20% 50% 30%/30% 60% 30% 40%;
  position: absolute;
  transform-style: preserve-3d;
  transform: rotateX(-25deg) rotate(-35deg) skewx(-15deg) translate(10px, -20px);
  top: -8px;
  left: -5px;
}

.reflections:before {
  content: "";
  display: block;
  position: absolute;
  height: 10px;
  width: 30px;
  background-image: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.15));
  bottom: 10px;
  right: 0px;
  transform: rotate(45deg);
}

.sparks .spark1 {
  display: block;
  height: 1px;
  width: 1px;
  background-color: #d1b82b;
  position: absolute;
  right: -20px;
  border-radius: 50%;
  bottom: -23px;
  transition: 0.4s;
  opacity: 0;
}

.sparks .spark2 {
  display: block;
  height: 3px;
  width: 3px;
  background-color: #d1b82b;
  position: absolute;
  right: 50px;
  border-radius: 50%;
  bottom: -10px;
  transition: 0.4s;
  opacity: 0;
}

.sparks .spark3 {
  display: block;
  height: 3px;
  width: 3px;
  background-color: #d1b82b;
  position: absolute;
  left: 50px;
  border-radius: 50%;
  bottom: -20px;
  transition: 0.4s;
  opacity: 0;
}

.sparks .spark4 {
  display: block;
  height: 3px;
  width: 3px;
  background-color: #d1b82b;
  position: absolute;
  left: 20px;
  border-radius: 50%;
  bottom: 20px;
  transition: 0.4s;
  opacity: 0;
}

@-moz-keyframes spark1 {
  0% {
    right: -5px;
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  20% {
    height: 3px;
    width: 3px;
    right: 0px;
    opacity: 1;
  }

  30% {
    right: -5px;
    opacity: 1;
    height: 3px;
    width: 3px;
  }

  70% {
    height: 3px;
    width: 3px;
  }

  100% {
    right: -60px;
    bottom: 40px;
    opacity: 0;
  }
}

@-webkit-keyframes spark1 {
  0% {
    right: -5px;
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  20% {
    height: 3px;
    width: 3px;
    right: 0px;
    opacity: 1;
  }

  30% {
    right: -5px;
    opacity: 1;
    height: 3px;
    width: 3px;
  }

  70% {
    height: 3px;
    width: 3px;
  }

  100% {
    right: -60px;
    bottom: 40px;
    opacity: 0;
  }
}

@-o-keyframes spark1 {
  0% {
    right: -5px;
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  20% {
    height: 3px;
    width: 3px;
    right: 0px;
    opacity: 1;
  }

  30% {
    right: -5px;
    opacity: 1;
    height: 3px;
    width: 3px;
  }

  70% {
    height: 3px;
    width: 3px;
  }

  100% {
    right: -60px;
    bottom: 40px;
    opacity: 0;
  }
}

@keyframes spark1 {
  0% {
    right: -5px;
    height: 1px;
    width: 1px;
    opacity: 0;
  }

  20% {
    height: 3px;
    width: 3px;
    right: 0px;
    opacity: 1;
  }

  30% {
    right: -5px;
    opacity: 1;
    height: 3px;
    width: 3px;
  }

  70% {
    height: 3px;
    width: 3px;
  }

  100% {
    right: -60px;
    bottom: 40px;
    opacity: 0;
  }
}

@-moz-keyframes spark2 {
  0% {
    height: 3px;
    width: 3px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    right: -20px;
    bottom: 100px;
    opacity: 0;
  }
}

@-webkit-keyframes spark2 {
  0% {
    height: 3px;
    width: 3px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    right: -20px;
    bottom: 100px;
    opacity: 0;
  }
}

@-o-keyframes spark2 {
  0% {
    height: 3px;
    width: 3px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    right: -20px;
    bottom: 100px;
    opacity: 0;
  }
}

@keyframes spark2 {
  0% {
    height: 3px;
    width: 3px;
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    right: -20px;
    bottom: 100px;
    opacity: 0;
  }
}

@-moz-keyframes spark3 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: 0px;
    bottom: 100px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@-webkit-keyframes spark3 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: 0px;
    bottom: 100px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@-o-keyframes spark3 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: 0px;
    bottom: 100px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@keyframes spark3 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: 0px;
    bottom: 100px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@-moz-keyframes spark4 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: -20px;
    bottom: -10px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@-webkit-keyframes spark4 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: -20px;
    bottom: -10px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@-o-keyframes spark4 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: -20px;
    bottom: -10px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}

@keyframes spark4 {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 1;
    height: 2px;
    width: 2px;
  }

  100% {
    left: -20px;
    bottom: -10px;
    opacity: 0;
    height: 3px;
    width: 3px;
  }
}



.tui-image-editor-header-logo {
  display: none;
}

.FIE_tools-bar label {
  margin-bottom: 0;
}

/* in card social share button alignment */
.social-bar {
  display: flex;
}

.social-bar div {
  margin: 0 5px;
}




/* Sidebar icons */
.icon_sidebar {
  background-color: #29344a;
  display: block;
  width: 100%;
  height: 18px;
  margin-bottom: 5px;
}

a:hover .icon_sidebar {
  background-color: #1d8cf8;
}

.icon_page {
  background-color: #29344a;
  width: 30px;
  height: 22px;
  margin-right: 10px;
  display: inline-block;
}

.icon_house {
  -webkit-mask: url(./assets/icons/icon_home.svg) no-repeat center;
  mask: url(./assets/icons/icon_home.svg) no-repeat center;
}

.icon_search {
  -webkit-mask: url(./assets/icons/icon_search.svg) no-repeat center;
  mask: url(./assets/icons/icon_search.svg) no-repeat center;
}

.icon_gallery {
  -webkit-mask: url(./assets/icons/icon_gallery.svg) no-repeat center;
  mask: url(./assets/icons/icon_gallery.svg) no-repeat center;
}

.icon_events {
  -webkit-mask: url(./assets/icons/icon_events.svg) no-repeat center;
  mask: url(./assets/icons/icon_events.svg) no-repeat center;
}

.icon_students {
  -webkit-mask: url(./assets/icons/icon_students.svg) no-repeat center;
  mask: url(./assets/icons/icon_students.svg) no-repeat center;
}

.icon_upload {
  -webkit-mask: url(./assets/icons/icon_upload.svg) no-repeat center;
  mask: url(./assets/icons/icon_upload.svg) no-repeat center;
}

.icon_archive {
  -webkit-mask: url(./assets/icons/icon_archive.svg) no-repeat center;
  mask: url(./assets/icons/icon_archive.svg) no-repeat center;
}

.icon_docs {
  -webkit-mask: url(./assets/icons/icon_docs.svg) no-repeat center;
  mask: url(./assets/icons/icon_docs.svg) no-repeat center;
}

.icon_admin {
  -webkit-mask: url(./assets/icons/icon_admin.svg) no-repeat center;
  mask: url(./assets/icons/icon_admin.svg) no-repeat center;
}

.icon_albums {
  -webkit-mask: url(./assets/icons/icon_albums.svg) no-repeat center;
  mask: url(./assets/icons/icon_albums.svg) no-repeat center;
}

.icon_trashcan {
  -webkit-mask: url(./assets/icons/icon_trashcan.svg) no-repeat center;
  mask: url(./assets/icons/icon_trashcan.svg) no-repeat center;
}



.copyright_bottom {
  font-size: 0.8rem;
  margin-left: 90px;
  padding-bottom: 30px;
}

.album-div {
  width: 100%;
}

.album-card {
  width: 90px;
  height: 90px;
  object-fit: cover;
  background: #fff;
  box-shadow:
    /* The top layer shadow */
    0 1px 1px rgba(0, 0, 0, 0.15),
    /* The second layer */
    0 10px 0 -5px #eee,
    /* The second layer shadow */
    0 10px 1px -4px rgba(0, 0, 0, 0.15),
    /* The third layer */
    0 20px 0 -10px #eee,
    /* The third layer shadow */
    0 20px 1px -9px rgba(0, 0, 0, 0.15);
  /* Padding for demo purposes */
  padding: 25px;
  border: 1px solid #eee;
}

.album-card:hover {
  transition: 0.4s;
  transform: rotate(-6deg) translate(-1px, -3px) scale(1.1);
}

/* My fav button-top-right */
#grey-icon .rs-btn,
#my-fav .rs-btn {
  background: none;
}

#my-fav .rs-icon {
  color: gold;
}

#grey-icon .rs-icon {
  color: #ccc;
}

#my-fav .rs-btn:hover svg {
  color: #ccc;
}

#grey-icon .rs-btn:hover svg {
  color: rgba(255, 215, 0, 0.6);
}


#added-album .rs-icon {
  color: #007bff;
}


#added-album .rs-icon,
#grey-icon .rs-icon,
.editing i {
  font-size: 18px;
}

#my-fav .rs-icon {
  font-size: 20px;
}

.rs-drawer-open .rs-drawer-has-backdrop {
  padding-right: 0;
}




.navbar-right .rs-dropdown-menu {
  padding: 15px;
}

.statsection .logolight,
.statsection .logodark {
  width: 140px;
}

#app-container .card-img-top {
  height: 240px;
  object-fit: contain;
}

#app-container .list-autre .card-img-top {
  height: 150px;
}


/* Toggle Gallery or Table */
.gallery-table.box {
  display: none;
}

.gallery-table.box.list-autre {
  display: block;
}

.list-autre #duplicate i {
  font-size: 9px;
}

.list-autre .social-bar {
  display: none;
}

.social-bar-drop {
  display: none;
}

.list-autre .social-bar-drop {
  display: block;
}

.list-autre #added-album .rs-icon,
.list-autre #grey-icon .rs-icon,
.list-autre .editing i,
.list-autre .social-bar-drop {
  font-size: 16px;
  height: auto;
}

@media (min-width: 1509px) {
  .list-autre .col-xl-3.col-lg-4.col-12.col-sm-6.mb-4 {
    max-width: 12.5%;
  }
}


@media (min-width: 1230px) and (max-width: 1509px) {
  .list-autre .col-xl-3.col-lg-4.col-12.col-sm-6.mb-4 {
    max-width: 16%;
  }
}

@media (min-width: 1000px) and (max-width: 1230px) {
  .list-autre .col-xl-3.col-lg-4.col-12.col-sm-6.mb-4 {
    max-width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .list-autre .col-xl-3.col-lg-4.col-12.col-sm-6.mb-4 {
    max-width: 33.3%;
  }
}

/* Modal Gallery open image */
.imgmod {
  max-height: 60vh;
  max-width: 100%;
  display: block;
}

.rs-modal-body {
  overflow: scroll;
}

.rs-btn.rs-btn-icon>.rs-icon-star {
  padding: 3px 0;
}

.pdfContainer {
  max-height: 60vh;
  max-width: 70%;
  overflow-x: auto;
  overflow-y: auto;
}

/* Scrollbar */
.dark {
  scrollbar-color: #0f1126 transparent;
}


/* Rich Edit Text */
div[editext='view']:before {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

button[editext="edit-button"],
button[editext="save-button"],
button[editext="cancel-button"] {
  background-color: transparent;
  border: none;
}

button[editext="edit-button"] {
  color: #ccc;
  padding: 0px;
}

button[editext="edit-button"]:before {
  content: "\e05f";
  font-family: 'simple-line-icons';
  font-size: 16px;
}

div[editext="edit-container"] {
  font-size: 14px;
}

div[editext="edit-container"] input {
  padding: 5px;
}

button[editext='save-button'] {
  color: #0cb30c;
}

button[editext='save-button']:before,
button[editext='cancel-button']:before {
  margin: 0;
}

button[editext='save-button'],
button[editext='cancel-button'] {
  padding: 0;
  font-size: 16px;
}

/* END Rich Edit Text */

/* Fn ModalAblum Share - buttons display in listing and preview */
.albumpreview .albumlistbtn {
  display: none;
}

.albumlisting .albumlistbtn {
  display: block;
}

.albumpreview .albumpreviewbtn {
  display: block;
}

.albumlisting .albumpreviewbtn {
  display: none;
}



.search-panel .rs-picker-daterange-menu {
  position: initial !important;
  width: 530px !important;
}

.search-panel .rs-picker-daterange .rs-input-group,
.search-panel .rs-input-group.rs-input-group-inside.rs-input-group-focus {
  border-color: #e5e5ea !important;
}

.search-panel .rs-input-group.rs-input-group-inside.rs-input-group-focus {
  outline: none !important;
}

/* Tag picker remove caret icon */
.rs-picker-input .rs-picker-toggle-caret:before {
  display: none;
}

.uploadblock .rs-btn.rs-btn-default {
  background: #3498ff;
  color: white;
  border: 1px solid white;
}

.uploadblock .rs-btn.rs-btn-default.rs-btn-disabled {
  color: #777;
  background: #eee;
}

/* Gallery - Editing Image Data */
.rs-btn-padding {
  padding-bottom: 35px;
}

.closeEdit {
  bottom: 55px;
  position: relative;
  padding: 10px;
  background: white;
  width: 100%;
  border-width: 0cap;
  z-index: 99999;
}

.dark .closeEdit {
  bottom: 65px;
  position: relative;
  padding: 20px;
  background: var(--dark);
  width: 100%;
  border-width: 0cap;
}


/* Editext TabPanel */
.tabpanel div[editext="view-container"] {
  margin-top: 0 !important;
}

/* Gallery Modal - Students image */
.st_profiles .hide_st_img img {
  height: 30px !important;
}

/* Form View - Tabs */
.react-tabs li {
  font-size: 16px !important;
}

.react-tabs__tab--selected {
  background: #007bff !important;
  border-color: unset !important;
  color: white !important;
  border-radius: 5px !important;
}

/* Special class for gridphoto for large screens */
@media (min-width: 1980px) {
  .col-xxl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
}

@media (min-width: 3000px) {
  #app-container .card-img-top {
    height: 340px !important;
  }

  #app-container .list-autre .card-img-top {
    height: 250px !important;
  }
}

/* Addons for Album page */
.albfav {
  color: gold;
  font-size: 24px;
  height: 24px;
}

.albbulk {
  color: #007BFF;
  font-size: 24px;
}

.albcur {
  font-size: 24px;
  height: 24px;
  line-height: 0;
}

.card.card-add,
.card.card-bulk,
.card.card-fav {
  background: transparent !important;
  box-shadow: none;
}

.card.card-add {
  border: 2px dashed #D0D0D0;
}

.card.card-bulk {
  border: 1px solid #e6e6e6;
}

.card.card-fav {
  border: 1px solid #e6e6e6;
}


/* Folder icons */
.icon_fold_small {
  background-image: url("./assets/icons/icon_folder.png");
}

.icon_fold_shared {
  background-image: url("./assets/icons/album_shared.png");
  display: none;
}

.icon_fold_small,
.icon_fold_shared {
  object-fit: contain;
  background-repeat: no-repeat;
  height: 18px;
  width: 22px;
  background-size: 16px;
  margin-right: 5px;
}

/* Folder icon if album shared or not */
.shared-1 .icon_fold_small {
  display: none;
}

.shared-1 .icon_fold_shared {
  display: block;
}


/* Bounding Box */
.bbox-container {
  z-index: 9;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.bbox {
  position: absolute;
  border: 2px rgba(57, 75, 89, 0.0) solid;
  transition: border 0.2s, box-shadow 0.2s;
  border-radius: 4px;
}

.bbox-summary-container {
  width: max-content !important;
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 10px !important;
  background-color: white !important;
  box-shadow: 5px 10px 10px 0 rgba(24, 24, 25, 0.3) !important;
  padding: 0 !important;
}

.bbox-image-container img {
  width: 120px !important;
  height: 100% !important;
  border-bottom: 5px solid white !important;
  border-left: 5px solid white !important;
  border-top: 5px solid white !important;
  object-fit: cover;
}

.bbox-summary-label {
  font-size: 14px !important;
  line-break: 16px !important;
}

.bbox-summary-info {
  padding: 20px 30px 20px 10px !important;
}

.bbox-selected {
  border: 2px solid white !important;
  padding-top: 10px !important;
  border-radius: 10px !important;
}

.grid-highlight {
  position: fixed;
  border: 1px solid #137cbd;
  background-color: rgba(19, 124, 189, 0.1);
}

.bbox-summary-label i.rs-icon-angle-right {
  color: #999 !important;
}

.bbox-summary-label .rs-icon-badge:before {
  content: "\f2c1";
  color: #999;
}


.dark .bbox-summary-info {
  background-color: var(--dark);
  color: white;
}

/* Cases */
/* Consent red */
.bbox-red .rs-icon-user:before {
  content: "\f071" !important;
}

.bbox-red .bbox-image-container img {
  border-bottom: 5px solid red !important;
  border-left: 5px solid red !important;
  border-top: 5px solid red !important;
}

.bbox-red .bbox-summary-label i {
  color: red !important;
}

/* Consent light-blue */
.bbox-blue .rs-icon-user:before {
  content: "\f071" !important;
}

.bbox-blue .bbox-image-container img {
  border-bottom: 5px solid #0069d9 !important;
  border-left: 5px solid #0069d9 !important;
  border-top: 5px solid #0069d9 !important;
}

.bbox-blue .bbox-summary-label i {
  color: #0069d9 !important;
}

/* Consent orange */
.bbox-orange .rs-icon-user:before {
  content: "\f002" !important;
}

.bbox-orange .bbox-image-container img {
  border-bottom: 5px solid orange !important;
  border-left: 5px solid orange !important;
  border-top: 5px solid orange !important;
}

.bbox-orange .bbox-summary-label i {
  color: orange !important;
}

/* Consent green */
.bbox-green .rs-icon-user:before {
  content: "\f132" !important;
}

.bbox-green .bbox-image-container img {
  border-bottom: 5px solid #0ab00a !important;
  border-left: 5px solid #0ab00a !important;
  border-top: 5px solid #0ab00a !important;
}

.bbox-green .bbox-summary-label i {
  color: #0ab00a !important;
}

/* BBox extra fields - second icon, then bullets */
.bbox-summary-label:nth-child(2):before,
.bbox-summary-label:nth-child(4):before {
  font-family: "rsuite-icon-font" !important;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  display: inline-block;
  text-transform: none;
  font-style: 14px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* For upload Cards Step 2 */
.gridupload {
  width: 150px;
}

.card-img-top.griduploadimg {
  height: 150px !important;
  width: 150px !important;
  border-radius: 5px !important;
  object-fit: cover;
  padding: 5px;
}

.card-img-top.griduploadimg:hover {
  opacity: 0.7;
}

.uploadselect,
.uploaddelete {
  top: 12px !important;
  height: 36px;
  width: 36px;
  background: white;
}

.uploaddelete i {
  font-size: 14px;
}

.stepbtn .rs-btn-group {
  width: 100%;
}

/* Drodpowns in Profile */
table.dataTable {
  width: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

table.dataTable td {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f3f3f3;
  outline: initial !important;
}

/* Verify Faces Modal */
.verify {
  width: 100%;
}

.verify .card {
  margin: 20px;
}

@media (min-width: 700px) {
  .verify {
    width: calc(100% / 2);
  }
}

@media (min-width: 900px) {
  .verify {
    width: calc(100% / 3);
  }
}

@media (min-width: 1280px) {
  .verify {
    width: calc(100% / 4);
  }
}

@media (min-width: 1600px) {
  .verify {
    width: calc(100% / 5);
  }
}

/* format 1:1 for images */
.verify .position-relative {
  height: 0;
  padding-bottom: 100%;
}

.gridverify {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}

.overlayverif {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0, 0, 0, 0.3);
}

.overlayverif:hover {
  opacity: 1;
}

.overlayverif i {
  font-size: 24px;
}

.roundbtn {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.roundbtn.rs-btn-green:hover {
  box-shadow: 2px 2px 10px 0 green;
  transform: scale(1.1);
}

.roundbtn.rs-btn-red:hover {
  box-shadow: 2px 2px 10px 0 red;
  transform: scale(1.1);
}


.makeref {
  position: absolute;
  left: -21px;
  top: -20px;
}

.makeref.triangle {
  display: none;
}

.makeref :hover {
  box-shadow: 2px 2px 10px 0 #f5c31d;
  transform: scale(1.1);
}

.lookup {
  position: absolute;
  right: -21px;
  top: -20px;
}

.w-200 {
  width: 200px;
}

.w-150 {
  width: 150px;
}

.rounded.griduploadimg {
  border-radius: 100% !important;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 0 0;
  border-color: gold transparent;
  left: 0;
  top: 0;
  position: absolute;
}

.selected {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 40px 40px;
  border-color: #37ab3c transparent;
  right: 0;
  bottom: 0;
  position: absolute;
}

.rejected {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 40px 40px;
  border-color: #eb3626 transparent;
  right: 0;
  bottom: 0;
  position: absolute;
}

/* Students lists verify Ref addon */
.ailink i {
  font-size: 20px;
  line-height: 1;
}


.rs-btn-toolbar .iconsminds-target-market,
.rs-btn-toolbar .iconsminds-chemical,
.rs-btn-toolbar .simple-icon-question,
.rs-btn-toolbar .iconsminds-compass-1,
.rs-btn-toolbar .iconsminds-photo,
.rs-btn-toolbar .iconsminds-calendar-4 {
  font-size: 22px;
  margin: 0 10px;
}

.referencemodal .bulkverifyinfo {
  display: none;
}

.bulkverifymodal .referenceinfo {
  display: none;
}


/* Event Calendar hidden sidebar */
.eventcalendar {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 600px;
  background: white;
  transition: transform 300ms;
  position: fixed;
  top: 100px;
  height: calc(100% - 100px);
}

.eventcalendar .p-4 {
  overflow-y: scroll;
}

.eventcalendar .eventbtns {
  padding-bottom: 100px;
}

.eventcalendar.box {
  right: -600px;
}

.eventcalendar.shown {
  transform: translateX(-600px);
}

.eventcalendar.box .app-menu-button {
  position: absolute;
  left: -47px;
  background: #4caf50;
  top: 45px;
  padding: 16px 25px 16px 16px;
  border-radius: 5px 0 0 5px;
  box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.1);
  line-height: 0;
  transition: transform 300ms;
}

.eventcalendar.box .app-menu-button:hover {
  transform: translateX(-10px);
}

.eventcalendar.box.shown .app-menu-button {
  position: absolute;
  left: -32px;
  background: #f44336;
  top: 22px;
  width: 32px;
  padding: 8px;
  border-radius: 5px 0 0 5px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
  line-height: 0;
  transition: 0.3s;
}

.eventcalendar.box.shown .app-menu-button:hover {
  transform: translateX(3px);
  transition: 0.1s;
}

/* Event thumb */
.eventlist .card-img-bottom {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.card-img-bottom.noeventthumb {
  width: 80px;
  height: auto;
  margin: auto;
  flex-direction: column;
  display: flex;
  padding-bottom: 60px;
}

/* Define Event and Album thumb */
.event_thumb_yes,
.event_thumb_no,
.album_thumb_yes,
.album_thumb_no {
  display: none;
}

.sbevent .event_thumb_yes,
.albumpreview .album_thumb_yes {
  display: block;
}

.sbevent .event_thumb_no,
.albumpreview .album_thumb_no {
  display: hide;
}

.event_thumb_yes .icon_events,
.album_thumb_yes .icon_albums {
  width: 14px;
  background: black;
  height: 14px;
  margin-right: 10px;
}

/* Big Calendar */
.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #007bff !important;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #007bff !important;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #5aa4f2 !important;
}

.rbc-toolbar {
  height: 80px;
  padding-bottom: 20px;
}

.rbc-toolbar button {
  border: none !important;
  font-size: 12px;
}

.rbc-toolbar span:nth-child(1) button:nth-child(2):before {
  content: '<';
  margin-right: 5px;
}

.rbc-toolbar span:nth-child(1) button:nth-child(3):after {
  content: '>';
  margin-left: 5px;
}

.rbc-header {
  padding: 10px 0;
}

.rbc-toolbar-label {
  margin-bottom: 60px;
  font-size: 18px;
}

.rbc-toolbar span:nth-child(1) {
  right: 0;
  position: absolute;
  padding-top: 65px;
}

.rbc-toolbar span:nth-child(3) {
  left: 0;
  position: absolute;
  padding-top: 65px;
}

.rbc-toolbar button.rbc-active,
.rbc-toolbar button:active {
  box-shadow: none !important;
}

.event_topbar img {
  width: 34px;
  height: 34px;
}

.list-thumbnail-letters {
  background: none;
  color: #007bff;
  border: 1px solid rgba(20, 20, 20, 0.1)
}

/* in events, dropdown icon is over calendar */
.rs-dropdown-toggle,
.rs-dropdown-toggle.rs-btn {
  z-index: 1 !important;
}



.fixedbtn {
  position: fixed;
  top: 100px;
  right: 10px;
  z-index: 999;
}


/* Filerobot Editor */
.sc-fznMAR.fgCecj {
  display: none;
}

.FIE_editor-content {
  overflow: hidden;
}

.FIE_topbar-center-options label {
  margin-bottom: 0;
}

.FIE_topbar {
  flex-direction: row-reverse;
}

.FIE_topbar-close-button {
  display: none;
}

.FIE_topbar-save-button {
  padding: 14px;
  margin-right: 20px;
  background: #12d912;
}

.FIE_topbar-save-menu-opener {
  padding: 14px;
}

.FIE_topbar-history-buttons {
  margin-left: 80px;
}

.icon_profile {
  font-family: "iconsminds";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  width: 1em;
  margin-right: 10px;
  opacity: .8;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_profile .student:before {
  content: '\ea70';
}

.icon_profile .paststudent:before {
  content: '\ea6b';
}

.icon_profile .staff:before {
  content: '\e92e';
}

.icon_profile .visitor:before {
  content: '\ea62';
}

@media (min-width: 1600px) {
  .col-xxl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.icon_sort_alphabet {
  display: block;
  background-image: url("./assets/icons/sort_alphabet.png");
  object-fit: contain;
  background-repeat: no-repeat;
  height: 20px;
  width: 20px;
  background-size: 20px;
  margin: 0 10px;
}

.icon_profile_bbox {
  bottom: 0;
  right: 0;
  position: absolute;
  margin: 10px;
}


/* Gridphoto selected */
.rounded-circle-selected {
  border: 3px solid #bfbfbf !important;
  border-radius: 50%;
}

.thumb_unknown i {
  font-size: 24px;
  margin: 0 5px;
}

/* Deselect Ref Point in Bulk Verify */
.refselected .simple-icon-target::before {
  font-family: "iconsminds";
  content: "\ea0c";
  color: red;
}

.refselected .rs-btn-primary.rs-btn-yellow {
  background: radial-gradient(gold 60%)
}


.add-profile .drop-area {
  min-height: 200px !important;
  width: 200px;
}

.react-contexify-option {
  margin-left: -150px !important;
}

/* Add new profile image */
.addprimg img {
  object-fit: contain;
}

.clearsearch {
  bottom: 65px;
  position: relative;
  padding: 5px 35px;
  background: white;
  width: 100%;
  border-top: 1px solid #eee;
}

.clearsearch button {
  margin: 10px 0;
}

.clearsearch .rs-btn+.rs-btn {
  margin-bottom: auto;
}

.dark .clearsearch {
  background: var(--dark);
  border-color: var(--darker);
}

#noduplicate {
  display: none;
}

#duplicate {
  display: block;
}

#duplicate i {
  color: red
}

#notverified {
  display: none;
}

#verified {
  display: block;
}

#verified i {
  color: #007bff
}

.prsize i,
.prsize svg {
  font-size: 18px;
  color: orange;
}

.prstip .rs-tooltip-inner {
  background-color: orange !important;
  text-align: left !important;
}

.prstip.prstop .rs-tooltip-arrow {
  border-top-color: orange !important;
}

.prstip.prsbtm .rs-tooltip-arrow {
  border-bottom-color: orange !important;
}

.prstip.prsright .rs-tooltip-arrow {
  border-right-color: orange !important;
}

.thOK {
  display: none;
}

.thSmall {
  display: block;
}

.sort_iq i,
.sort_iq svg {
  margin: 0 10px;
  font-size: 20px;
  color: #5b5b5b;
}

#updatetodo .updategreen {
  display: none;
}

#updatenotvalid .updategreen {
  display: none;
}

#updatenotvalid .updateorange {
  display: none;
}

#updateok .updateorange {
  display: none;
}

#updateok .updatered {
  display: none;
}

#updatetodo .updatered {
  display: none;
}

#updateok .update-circle,
#updateok strong {
  color: #7bc043;
}

#updatetodo .update-circle,
#updatetodo strong {
  color: red;
}

#updatenotvalid .update-circle,
#updatenotvalid strong {
  color: red;
}

#nomedia .update-circle,
#nomedia strong {
  color: orange;
}

#sisnotok .update-circle,
#sisnotok strong {
  color: orange;
}

#sisok .update-circle,
#sisok strong {
  color: #4a87fa;
}

/* Change the cursor */
.cursor-point {
  cursor: pointer
}

.suggestion:hover {
  background-color: lightgray;
  color: #0056b3;
}

.filterhover:hover {
  background-color: lightgray;
}


.w-events {
  width: 160px !important;
}

.wbr200,
.w-events {
  white-space: nowrap;
  word-break: break-all;
  justify-content: space-between;
}

.wbr200 span,
.w-events span {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-menu.eventcalendar.box.shown .side_add_ev a {
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 5px;
  width: 105px;
}

.searchDrawerOther {
  opacity: 0.4;
}


.searchDrawerGallery,
.gallerypage .searchDrawerOther {
  display: none !important;
}

.gallerypage .searchDrawerGallery {
  display: block !important;
}


/* Welcome page */
.update_status {
  width: 290px;
  z-index: 999;
  position: absolute;
  top: 120px;
  right: 40px;
}

.update_info {
  width: fit-content;
  margin-top: 100px;
  margin-bottom: 10px;
}

.extra_info {
  width: fit-content;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) {
  .wmt-50 {
    bottom: -100px;
    position: relative;
  }
}

.wmt-50 .card {
  width: 125px;
  height: 125px;
}

.wmt-50 .card .card-img-overlay {
  background: white;
  color: #121314;
}

.wmt-50 .card .card-img-overlay .homequick i {
  background-color: #121314;
}

.sb-hero {
  padding-top: 80px;
  padding-bottom: 0;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 140px;
}

.sb-hero .hero-title {
  font-size: 46px;
  font-weight: 600;
  letter-spacing: -1px;
  line-height: 48px;
  margin-bottom: 22px;
}

@media only screen and (max-width: 1366px) {
  .sb-hero .hero-title {
    font-size: 44px;
    line-height: 46px;
  }
}

@media only screen and (max-width: 991px) {
  .sb-hero .hero-title {
    font-size: 40px;
    line-height: 42px;
  }
}

@media only screen and (max-width: 767px) {
  .sb-hero .hero-title {
    font-size: 36px;
    line-height: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .sb-hero .hero-title {
    font-size: 28px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 420px) {
  .sb-hero .hero-title {
    font-size: 24px;
    line-height: 26px;
  }
}

.sb-hero .hero-title>span {
  color: #4a87fa;
}

.frontpage-banner-content .school-logo {
  width: 100%;
  margin-bottom: 30px;
  max-height: 260px;
  max-width: 280px;
  object-fit: contain;
}

.frontpage-banner-section.frontpage-banner-style-three .hero-desc {
  color: black;
  padding-right: 150px;
  font-size: 25px;
}

.frontpage-banner-section.frontpage-banner-style-three .hero-mockup-area {
  position: relative;
}

@media only screen and (max-width: 1366px) {
  .frontpage-banner-section .hero-mockup-area {
    margin-right: 0px;
    margin-left: -60px;
  }
}

@media only screen and (max-width: 1620px) {
  .frontpage-banner-section .hero-mockup-area {
    margin-right: -45px;
    margin-left: -30px;
  }
}

.frontpage-banner-section .hero-mockup-area {
  margin-right: -40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  z-index: -99;
}

@media only screen and (max-width: 991px) {
  .frontpage-banner-section .hero-mockup-area {
    margin-right: 0px;
    justify-content: center;
  }
}

.hero-mockup-thumb img {
  width: 100%;
  max-width: 500px;
  object-fit: cover;
  border-radius: 14px;
}



.sb-hero>.element-group>.element {
  z-index: 0;
  position: absolute;
}

@media only screen and (max-width: 1366px) {
  .sb-hero>.element-group>.element {
    display: none;
  }
}

.sb-hero>.element-group>.element.one {
  top: 140px;
  left: 25px;
}

.sb-hero>.element-group>.element.two {
  top: 380px;
  left: 0;
}

.sb-hero .hero-mockup-area .element-group>.element.one {
  bottom: 100px;
  left: -90px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .sb-hero .hero-mockup-area .element-group>.element.one {
    display: none;
  }
}


.sb-hero .hero-mockup-area .element-group>.element.three {
  top: -50px;
  right: -70px;
}

.sb-hero .hero-mockup-area .element-group>.element.two {
  right: -42px;
  top: 200px;
}

.sb-hero .hero-mockup-area .element-group>.element {
  position: absolute;
}


.service-item {
  position: relative;
  padding: 35px 30px 30px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
  -webkit-transition: all 0.25s linear 0ms;
  transition: all 0.25s linear 0ms;
  background-color: white;
  box-shadow: 3px 3px 50px rgba(0, 0, 0, 0.075);
  border-radius: 15px;
  overflow: hidden;
}

.service-item .service-icon {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  min-height: auto;
  line-height: auto;
  margin-bottom: 20px;
  background: none;
}

.service-item .title {
  font-weight: normal;
  font-size: 30px;
  line-height: 1.25em;
  margin-bottom: 22px;
}

.service-item p {
  font-size: 14px;
  color: #6a6a6a;
}

.service-item .read-more-text {
  font-weight: normal;
  color: black;
  font-size: 18px;

  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  padding-top: 5px;
}

.services-block.style-three .element-group>.element.one {
  left: 0;
}

.services-block.style-three .element-group>.element {
  z-index: 0;
  position: absolute;
}


.curation {
  border-left: 1px solid #a7a7aa;
  padding-left: 15px;
}

.curation.cur_red {
  border-left: 1px solid red;
}

.curation.cur_orange {
  border-left: 1px solid orange;
}

.curation.cur_green {
  border-left: 1px solid #a7a7aa;
}

.cur_red .cur_number,
.cur_orange .cur_number,
.cur_green .cur_number {
  font-weight: 700;
}

.cur_red .cur_number {
  color: red;
}

.cur_orange .cur_number {
  color: orange;
}

.cur_green .cur_number {
  color: green;
}

/* .learning-block .element-group>.element.one {
  top: 160px;
  left: calc(50% - 700px);
} */

/* .learning-block .element-group>.element {
  z-index: -1;
  position: absolute;
} */

/* .learning-block {
  position: relative;
  padding-top: 60px;
} */

/* .learning-block::before {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1060px;
  background-color: rgb(83 51 237 / 9%);
  top: 0;
  height: calc(100% - 60px);
  z-index: 0;
  border-radius: 20px;
} */


.section-title .title-main {
  font-size: 30px;
  font-weight: 500;
  /* line-height: 1.12em; */
  margin-bottom: 40px;
}

.section-title .sub-title {
  font-weight: 500;
  font-size: 24px;
  /* margin-bottom: 15px; */
  color: #5333ed !important;
}


.pricing-box:hover {
  box-shadow: 3px 3px 70px rgba(0, 0, 0, 0.15);
}

.pricing-box {
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-bottom: 30px;
  padding: 50px;
  box-shadow: 3px 3px 70px rgba(0, 0, 0, 0.075);
  background-color: white;
}

.pricing-box .pricing-header .pricing-title {
  text-transform: capitalize;
  letter-spacing: 0;
  font-size: 30px;
  margin: 20px 0 40px 0;
  font-weight: 500;
  color: black;
  line-height: 1.15;
}

.pricing-box .pricing-btn-area .btn-default {
  font-size: 14px;
  border-radius: 7px;
  color: white;
  background-color: #7bc043;
}

.pricing-box .pricing-btn-area .btn-default.sb_learning {
  background-color: #5333ed;
}

.pricing-box .sb_lc_title {
  font-weight: 500;
  font-size: 20px;
}
.col-sb-lc, .dark .col-sb-lc {
  background-color: #5333ed;
  color: white;
}
.lc-copy {
  border: 1px solid #fefefe4d;
  padding: 4px 8px;
  border-radius: 4px;
}


.logo_learning img {
  max-height: 45px;
  max-width: 100%;
  object-fit: contain;
}

.school-logo {
  background: rgb(255, 255, 255);
  border-radius: 10px;
  padding: 5px;
}

.wrapper {
  word-break: break-word;
}

/* Welcome page vote section */
.text-feature-block .title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.12em;
  margin-bottom: 30px;
}

.text-feature-block .sub-title {
  font-size: 20px;
  font-weight: 500;
  color: #9a9a9a;
  line-height: 1.55em;
  margin-bottom: 38px;
}

.btn-vote {
  border-radius: 7px;
  padding: 10px 25px 8px;
  color: #7bc043;
  border: 1px solid #7bc043;
  background-color: transparent;
  font-size: 16px;
  margin-top: 8px;
}

.poll-thumb img {
  width: 100%;
  max-width: 500px;
}


/* Profile view card */
.card.card-profile {
  width: 300px;
}


/* Dark Mode */
body.dark {
  background: var(--darker);
}

.dark .icon_page {
  background-color: white;
}

.dark #app-container .btn-toggle-open {
  display: block;
}

.dark #app-container .btn-toggle-close {
  display: none;
}

.dark #app-container .btn-toggle-lg {
  background-color: var(--blue);
}

.dark #app-container .btn-toggle-lg .btn-toggle-open {
  margin-right: 30px;
  margin-left: 10px;
  height: 30px;
  line-height: 2.14285714;
  font-size: 14px;
}

.dark #app-container .btn-toggle-open {
  color: #ffffff;
  display: block;
  transition: margin 0.2s ease-in-out;
}

.dark #app-container .btn-toggle-open::after {
  width: 22px;
  height: 22px;
  left: 4px;
  top: 4px;
  border-radius: 11px;
  content: '';
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  transition: left 0.2s ease-in-out, margin-left 0.2s ease-in-out, width 0.2s ease-in-out;
  left: 100%;
  margin-left: -26px;
}

.dark #app-container {
  color: var(--grey);
}

.dark #app-container #darkmode {
  background-color: #25b3f5;
}

.dark #app-container h1,
.dark #app-container h2,
.dark #app-container h3,
.dark #app-container h4,
.dark #app-container h5,
.dark #app-container h6 {
  color: var(--grey);
}

.dark #app-container .navbar {
  background: var(--darker);
  box-shadow: 0px 0px 6px rgba(82, 82, 82, 0.3);
  -webkit-box-shadow: 0px 0px 6px rgba(82, 82, 82, 0.3);
  -moz-box-shadow: 0px 0px 6px rgba(82, 82, 82, 0.3);
}

.dark #app-container .navbar .search {
  background: var(--dark);
}

.dark #app-container .dropdown-menu {
  background: var(--blue);
  border-color: var(--darkblue);
}

.dark #app-container .dropdown-item {
  color: var(--white);
}

.dark #app-container .dropdown-item:hover,
.dark #app-container .dropdown-item:focus {
  opacity: 0.7;
  background: none;
}

.dark #app-container .menu .main-menu {
  background: var(--dark);
  box-shadow: 1px 0px 5px rgb(0, 0, 0, 0.7), 1px 0px 10px rgba(0, 0, 0, 0.4);
  border: none;
}

.dark #app-container .menu .main-menu ul .active::before {
  background: var(--white);
}

.dark #app-container .menu .main-menu ul .active span {
  color: var(--white);
}

.dark #app-container .menu .main-menu span {
  color: #4a87fa;
}

.dark #app-container .menu .main-menu a:hover span {
  color: #efefef;
  font-weight: 500;
}

.dark #app-container .sideMenu .icon_sidebar {
  background-color: #4a87fa;
}

.dark #app-container a:hover .icon_sidebar {
  background-color: #efefef;
}

.dark #app-container .rs-divider {
  background-color: var(--darker);
}

/* top menu logout */
.dark #app-container a {
  color: var(--grey);
}

.dark #app-container .text-muted {
  color: #ccc !important;
}

.dark #app-container .card {
  background: var(--dark);
}

.dark #app-container .rs-drawer-content {
  position: absolute;
  background-color: var(--dark);
}

.dark #app-container .rs-drawer-header .rs-drawer-header-close {
  color: var(--white);
}

.dark #app-container .rs-uploader-draggable .rs-uploader-trigger-customize .rs-uploader-trigger-btn {
  background: var(--blue);
  border: 1px dashed var(--darblue);
  color: var(--white);
}

.dark #app-container .menu .main-menu ul .scrollTop span {
  color: var(--white);
}

.dark #app-container .separator {
  border-bottom: 1px solid var(--dark);
}


.dark #app-container i.large-icon {
  color: var(--blue);
}

/* Dark large Toggle*/
.dark #app-container .rs-btn-toggle.rs-btn-toggle-lg {
  background: #39315a;
  box-shadow: 1px 1px 1px rgba(2, 2, 2, 0.9);
}

/* Dark toggle menu with Search*/
.dark #app-container .rs-picker-toggle {
  background-color: var(--dark) !important;
  border: 1px solid var(--dark);
}

.dark #app-container .rs-picker-search-bar-input {
  background: var(--darker) !important;
  border: 1px solid var(--darker) !important;
}


.dark #app-container .rs-picker-menu.fade.in.placement-bottom-start.rs-picker-select-menu {
  background: var(--dark) !important;
  border: 1px solid var(--darkblue);
  margin-top: 5px;
}

.dark #app-container .rs-picker-menu.rs-picker-check-menu {
  background: var(--dark) !important;
  border: 1px solid var(--darkblue);
  margin-top: 5px;
}

.dark #app-container .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  background: var(--blue);
  border: none;
  color: var(--white);
}

.dark #app-container .rs-picker-search-bar-input::-webkit-input-placeholder,
.dark #app-container .rs-picker-search-bar-input::-moz-placeholder {
  color: var(--white) !important;
}

.dark #app-container .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
.dark #app-container .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: var(--white);
  background-color: var(--blue);
}

.dark #app-container .rs-check-item:not(.rs-checkbox-disabled):hover,
.dark #app-container .rs-check-item-focus {
  color: var(--white);
  background-color: var(--blue);
}

.dark #app-container .navbar .search input {
  color: var(--white);
}

.dark #app-container .rs-table,
.dark #app-container .rs-table-row-header {
  background: transparent;
}

.dark #app-container .rs-table-cell {
  background: var(--dark);
  border-color: var(--darker);
}

.dark #app-container .rs-table-row {
  border-bottom: 1px solid var(--darker)
}

.dark #app-container .rs-table-hover .rs-table-row-header:hover .rs-table-cell-group,
.dark #app-container .rs-table-hover .rs-table-row-header:hover .rs-table-cell {
  background: var(--dark);
}

.dark #app-container .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.dark #app-container .rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.dark #app-container .rs-table-hover .rs-table-row:hover .rs-table-cell {
  background: var(--darker);
}

.dark #app-container .app-menu {
  background: var(--dark);
}

.dark #app-container .app-menu .app-menu-button {
  background: var(--blue);
}

.dark #app-container .add-student .drop-area .card-body {
  background: var(--darker);
}

.dark #app-container .rs-input {
  background-color: var(--darker);
  border: 1px solid var(--darker);
}

.dark #app-container .rs-picker-input {
  background: var(--darker);
  border: 1px solid var(--darker);
}

.dark #app-container .icon-cards-row i {
  color: var(--blue);
}

.dark .separator {
  border-color: var(--darker) !important;
}

.dark .switch input~label .bulb {
  left: 22px;
}

.dark .switch input~label .bulb>.bulb-center {
  background-color: #feed6b;
  box-shadow: inset 0 0 0 2px #fdec6a, 0 0 10px 1px #bca83c, 0 0 10px 2px #a1664a;
}

.dark .switch input~label .bulb>.sparks .spark1 {
  height: 1px;
  width: 1px;
  animation: spark1 2s ease-in-out;
  animation-delay: 0.4s;
}

.dark .switch input~label .bulb>.sparks .spark2 {
  height: 1px;
  width: 1px;
  animation: spark2 2.4s ease-in-out;
  animation-delay: 0.4s;
}

.dark .switch input~label .bulb>.sparks .spark3 {
  height: 1px;
  width: 1px;
  animation: spark3 2s ease-in-out;
  animation-delay: 0.9s;
}

.dark .switch input~label .bulb>.sparks .spark4 {
  height: 1px;
  width: 1px;
  animation: spark4 1.7s ease-in-out;
  animation-delay: 0.9s;
}

.dark .switch label {
  background-color: #39315a;
  border-radius: 100px;
  display: block;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2), inset 0 0 0px -2px rgba(0, 0, 0, 0.4);
}

.dark .switch label .bulb .bulb-center {
  left: 0
}

.dark .switch label .bulb .bulb-center::after {
  background-color: #fef401;
  box-shadow: 0 0 0px 4px #fdb843;
}

.dark #app-container .rs-dropdown-menu {
  background: var(--dark);
}

.dark #app-container .rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:hover,
.dark #app-container .rs-dropdown-menu>.rs-dropdown-item>.rs-dropdown-item-content:focus {
  color: white;
  background-color: var(--darker);
}

.dark #app-container .albumdelete .btn,
.dark #app-container .albumshare .btn {
  color: white !important;
}

/* Edittext dark custom */
.dark #app-container .edittext input,
.dark #app-container .edittext textarea {
  background: #3498ff;
  border: none;
  border-radius: 4px;
}

.dark #app-container button[editext='edit-button']:hover,
.dark #app-container button[editext='save-button']:hover,
.dark #app-container button[editext='cancel-button']:hover {
  background: var(--setdarker);
}

/* Modal */
.dark #app-container .rs-modal-backdrop.in {
  opacity: 0.65;
}

.dark .rs-modal-wrapper {
  background: rgba(10, 10, 20, 0.4);
}

/* FB IG buttons */
.dark #app-container .social-bar .socialcolor svg {
  fill: white;
}

/* Dark theme for cards in albums */
.dark #app-container .menu .main-menu ul .active i {
  background: white;
}

.dark #app-container .rs-btn-ghost {
  border-width: 2px;
}

.dark #app-container .rs-btn-ghost i {
  font-weight: 700;
}

.dark #app-container .rs-btn-ghost.rs-btn-violet {
  color: #8212df;
  border-color: #8212df;
}

.dark #app-container .rs-btn.rs-btn-primary.rs-btn-violet {
  background-color: #8212df;
}

/* Upload Rsuite Steps */
.dark #app-container .rs-steps-item.rs-steps-item-status-process .rs-steps-item-title {
  color: white;
  font-weight: 500;
}

.dark #app-container .rs-steps-item-status-process .rs-steps-item-tail,
.dark #app-container .rs-steps-item-status-process .rs-steps-item-title::after {
  border-color: var(--setdark);
}

.dark #app-container hr {
  border-top: 1px solid var(--setdark);
}

.dark #app-container .uploadblock .rs-btn.rs-btn-default {
  background: #3498ff;
  border: 1px solid var(--setdark);
  color: white;
}

.dark #app-container .uploadblock .rs-btn.rs-btn-default.rs-btn-disabled {
  background: var(--setdarker);
  border: 1px solid var(--setdark);
  color: #777;
}

.dark #app-container .sort_iq i {
  color: var(--grey);
}

.dark #app-container #process {
  background-color: var(--dark);
}

.dark #app-container .about-area {
  background-color: #1e1e2f;
}

.dark #app-container .custom-control-label::before {
  background: #1e1e2f;
  border: 1px solid #4aa2f7;
}

.dark .custom-control-input:checked~.custom-control-label::before,
.dark .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.dark .custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #4aa2f7 !important;
  border: 1px solid #4aa2f7 !important;
}

/* welcome */
.dark .service-item,
.dark .pricing-box {
  background-color: #151933;
}

.dark .learning-block::before {
  background-color: rgba(21, 25, 51, 0.5);
}

.dark .service-item .service-icon {
  background: rgba(25, 25, 25, 0.2);
}

.dark .service-item .read-more-text {
  color: white;
}

.dark .sb-hero>.element-group>.element.two {
  opacity: 0;
}

.dark .learning-block .element-group>.element.one {
  opacity: 0.7;
  filter: grayscale(100%);
}

.dark .sb-hero .hero-mockup-area .element-group>.element.one {
  filter: grayscale(100%);
  opacity: 0.2;
}

.dark .service-item p,
.dark .text-feature-block .sub-title {
  color: #bfbebe;
}

.dark #grey-icon .rs-btn,
.dark #my-fav .rs-btn {
  background: var(--dark);
}

.dark #my-fav .rs-btn:hover {
  background: var(--darker);
}

.dark #app-container .card-add,
.dark #app-container .card-bulk,
.dark #app-container .card-fav {
  border-color: var(--darkmenubkg);
}

.dark #app-container .album-card {
  background: var(--darker);
  box-shadow: 0 10px 0 -6px #332e57, 0 19px 0 -12px #383c5e;
  border-color: var(--darker);
}

/* Calendar */
.dark .rbc-month-view,
.dark .rbc-day-bg+.rbc-day-bg,
.dark .rbc-month-row+.rbc-month-row {
  border-color: var(--darker);
}

.dark .rbc-off-range-bg,
.dark .rbc-now.rbc-current,
.dark .rbc-today {
  background-color: var(--darker);
}

.dark .rbc-header {
  background-color: var(--darkmenubkg);
}

.dark .rbc-header,
.dark .rbc-header+.rbc-header {
  border-color: var(--darkmenubkg);
}

.dark .rbc-calendar button.rbc-active {
  background-color: var(--dark) !important;
  color: var(--darkmenubkg) !important;
  font-weight: 500;
}

.dark .rbc-calendar button {
  background-color: var(--dark) !important;
  color: var(--white);
  font-weight: 300;
}

.dark .rbc-toolbar button:hover {
  color: #0a81d0;
  background-color: var(--darker);
}

.dark .rs-calendar {
  background: var(--darker);
}

.dark .rs-picker-daterange-header,
.dark .rs-picker-daterange-menu .rs-calendar:first-child {
  border-color: var(--darker);
}

/* Dark Administration */
.dark .settings .fixed-scroll.true,
.dark .settings .rs-navbar.rs-navbar-inverse.nav-toggle,
.dark .settings .rs-sidenav.rs-sidenav-inverse.rs-sidenav-collapse-in,
.dark .settings .navbar.fixed-top,
.dark .settings .rs-sidebar.rs-sidebar-collapse,
.dark .settings .rs-sidenav-body,
.dark .menu-default.settings {
  background-color: var(--darker) !important;
  border-color: var(--darker);
  box-shadow: none !important;
}

.dark .settings .rs-content {
  background: var(--dark);
}

.dark .settings .adminhead::before {
  background-color: var(--darker);
}

.dark .settings,
.dark .settings label,
.dark .settings p,
.dark .settings h1,
.dark .settings h2,
.dark .settings h3,
.dark .settings h4,
.dark .settings h5,
.dark .settings h6,
.dark .settings a {
  color: white !important;
}

.dark .settings .pricing-box {
  background-color: var(--darker);
}

.dark .settings .card {
  background-color: var(--darker);
  border-color: var(--darker);
  box-shadow: none;
}

.dark .settings .sblearningbox,
.dark .settings .pricing-box {
  background-color: var(--darker) !important;
  border-color: var(--darker);
}

.dark .settings .service-item {
  background-color: var(--dark) !important;
}

.dark .settings .settingcard {
  background-color: var(--dark);
  border-color: var(--darker);
  color: white !important;
}

.dark .settings .rs-uploader-trigger-btn {
  color: white;
  background-color: var(--darkmenubkg);
  border: none;
}

.dark .settings .rs-sidenav-collapse-in .rs-sidenav-nav .rs-nav-item-active>.rs-nav-item-content {
  background-color: var(--dark) !important;
}

.dark .settings .rs-sidenav-inverse .rs-sidenav-nav .rs-nav-item:not(.rs-nav-item-active)>.rs-nav-item-content {
  border: none
}

.dark .settings .admin_primary {
  color: var(--ad_primary);
}


.dark .settings .rs-btn-ghost.rs-btn-red i:before {
  color: red;
}

.dark .settings .rs-divider {
  background-color: var(--darker);
}

.dark .settings .setinfo {
  color: #bbb
}

.dark .settings .rs-btn-ghost.rs-btn-red.rs-btn-disabled .rs-icon {
  font-weight: 500;
}

.dark .settings .rs-btn-ghost.rs-btn-red.rs-btn-disabled i:before {
  color: #fd2222;
}

.dark .settings .rs-btn-ghost.rs-btn-red.rs-btn-disabled {
  border: 2px solid red;
}

.dark .settings .rs-btn-toggle {
  background-color: var(--darker);
}

.dark .settings .rs-btn-toggle-checked {
  background-color: var(--darkmenubkg);
}

.dark .settings .rs-input-group:not(.rs-input-group-inside) {
  border: none;
}

.dark .settings .rs-input-number {
  background: var(--darkmenubkg);
}

.dark .settings .consentdiv {
  background-color: var(--dark);
  border: 1px solid var(--darkmenulink);
  box-shadow: none;
}

.dark .settings .consentdiv h4 {
  color: var(--darkmenubkg) !important;
}

.dark .settings .rs-progress {
  color: white;
}

.dark .settings .leaveadmin i:before {
  color: gold;
}

.dark .rs-picker-menu {
  background-color: var(--dark);
  color: white;
  font-weight: 300;
  border: 1px solid var(--darker)
}

.dark .rs-checkbox-checked {
  background-color: var(--darker);
}

.dark .rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  background-color: var(--darker);
  border-color: var(--darker);
}

.dark .rs-picker-check-menu-items .rs-picker-check-menu-group-title {
  background-color: var(--darker);
  color: white;
}

.dark .rs-picker-check-menu-items .rs-picker-check-menu-group-title:hover {
  background-color: var(--darkmenubkg);
}

.dark .rs-check-item-focus,
.dark .rs-check-item:not(.rs-checkbox-disabled):hover {
  background-color: var(--darker);
  color: var(--darkmenubkg);
}

/* Modal */
.dark .rs-modal-content {
  background-color: var(--dark);
  color: white;
  border: 1px solid var(--darkmenulink);
}


.dark .rs-modal-title,
.dark .rs-drawer-title {
  color: white;
}

.dark .rs-modal-body a i:before {
  color: #1675e0;
}

/* Right Drawer EDIT */
.dark .rs-drawer-content {
  background-color: var(--dark);
  color: white;
  border-left: 1px solid var(--darkmenuhover);
}

.dark .rs-drawer-content .react-tabs__tab-list {
  border-color: var(--darkmenubkg);
}

.dark .react-tabs__tab {
  border: none;
}

/* Editext */
.dark div[editext="edit-container"] input {
  background-color: var(--darker);
  border-color: var(--darker);
}

.dark .styles-module_Editext__button__sxYQX:hover {
  background-color: var(--darker);
}

.dark .rs-picker-select-menu-item:not(.rs-picker-select-menu-item-disabled):hover,
.dark .rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: var(--darkmenubkg);
  background-color: var(--darker);
  text-decoration: none;
}

.dark .rs-picker-input {
  border-color: var(--darker);
  background-color: var(--darker);
}

.dark .rs-picker-default .rs-btn,
.dark.rs-picker-input .rs-btn,
.dark .rs-picker-default .rs-picker-toggle,
.dark .rs-picker-input .rs-picker-toggle {
  background: var(--darker) !important;
  border-color: var(--darker) !important;
}

.dark .rs-picker-select-menu-item {
  color: #eee;
}

.dark .rs-picker-toolbar {
  border-color: var(--darker);
}

.dark .rs-picker-toolbar-option:not(:last-child)::before {
  background-color: var(--darkmenubkg);
}

.dark .rs-picker-toolbar-option {
  color: var(--darkmenubkg);
}


.dark .albumlistbtn i {
  color: var(--grey);
}

/* Gallery */
.dark .refreshIcon {
  color: #efefef;
}

/* Album cards */
.collection {
  background: white;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 4px rgba(22, 22, 22, 0.035);
  padding: 0px 8px 5px;
  height: 100%
}

/* Gallery cards */
.collection-gallery {
  background: white;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 4px rgba(22, 22, 22, 0.035);
  padding: 4px 18px 12px;
  height: 100%
}

.collection-portalPlus {
  background: white;
  border-radius: 10px;
  box-shadow: 1px 2px 10px 4px rgba(22, 22, 22, 0.035);
  padding: 4px 18px 12px;
  height: 100%
}
.collection-portalPlus span:first-of-type {
  display: flex;
  justify-content: center;
}

.collection-thumb {
  position: relative;
  padding: 5px 5px 0;
  margin: auto 0;
}

.collection-thumb-gallery {
  position: relative;
  margin: 10px 0 10px 0;
}

.collection-thumb-gallery a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-thumb-portal {
  position: relative;
  margin: 15px 0;
}

.collection-thumb-portal img {
  max-height: calc(100vw * 0.10);
  object-fit: contain;
  width: 100%;
  max-width: calc(100% - 40px);
}

.collection-thumb-gallery img {
  max-height: 10vw;
  object-fit: contain;
  width: 100%;
  max-width: calc(100% - 40px);
}

.collection-thumb img {
  max-height: calc(100vw * 0.11);
  object-fit: contain;
  max-width: 105%;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .collection-thumb img {
    max-height: calc(100vw * 0.18);
  }
}

.collection-top .rs-tag {
  width: fit-content;
}

.collection-top i {
  font-size: 24px !important;
}

.collection-fav-alb .rs-btn.rs-btn-icon>.rs-icon {
  position: unset;
  top: unset;
  left: unset;
  display: unset;
  width: unset;
  height: unset;
  padding: unset;
  line-height: unset;

}

.collection-fav-alb .rs-btn.rs-btn-icon {
  padding-left: unset;
  height: unset;
  position: unset;
}

.collection-fav-alb .rs-btn {
  padding: unset;
}

.collection-top .social-bar div {
  margin: 0 6px 0 0;
}

.collection-bottom {
  padding-top: 10px;
  border-top: 1px solid #efefef;
  margin-top: 10px;
}

.collection-bottom i {
  font-size: 18px;
}

.collection .custom-control-label:before {
  width: 16px;
  height: 16px;
}

.collection .custom-control-label:after {
  top: 5px;
}


.collection .custom-control-input:checked~.custom-control-label::before,
.collection .custom-checkbox .custom-control-input:checked~.custom-control-label::before,
.collection .custom-radio .custom-control-input:checked~.custom-control-label::before,
.collection-gallery .custom-control-input:checked~.custom-control-label::before, .collection-gallery .custom-checkbox .custom-control-input:checked~.custom-control-label::before, .collection-gallery .custom-radio .custom-control-input:checked~.custom-control-label::before,
.collection-portalPlus .custom-control-input:checked~.custom-control-label::before, .collection-portalPlus .custom-checkbox .custom-control-input:checked~.custom-control-label::before, .collection-portalPlus .custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #4aa2f7 !important;
  box-shadow: initial !important;
  border: 1px solid #4aa2f7 !important;
}


.collection #grey-icon .rs-btn:hover .rs-icon-star::before {
  color: gold;
}

.collection #my-fav .rs-btn:hover .rs-icon-star::before {
  color: #ccc;
}

.collection #grey-icon:hover .rs-icon-bookmark-o::before {
  color: #007bff;
}

.collection #added-album:hover .rs-icon-bookmark-o::before {
  color: #ccc;
}

.collection #grey-icon .rs-btn:hover {
  background: none !important;
  color: #ccc;
}

.collection .favbutton .rs-btn {
  background: none !important;
}

.collection-fav-alb #grey-icon .rs-btn,
.collection-fav-alb #my-fav .rs-btn {
  background: none;
}

.dark .collection-fav-alb #grey-icon .rs-btn,
.dark .collection-fav-alb #my-fav .rs-btn {
  background: none;
}

.dark .collection, .dark .collection-gallery, .dark .collection-portalPlus {
  background-color: var(--dark);
}

.dark .collection-bottom {
  border-color: var(--darker);
}

.collection-top-right {
  display: flex;
  align-items: center;
}

@media (min-width: 1509px) {
  .list-autre .col-xl-3.col-lg-4.col-md-6.col-sm-6 {
    max-width: 12.5%;
  }
}

@media (min-width: 1230px) and (max-width: 1509px) {
  .list-autre .col-xl-3.col-lg-4.col-md-6.col-sm-6 {
    max-width: 16%;
  }
}

@media (min-width: 1000px) and (max-width: 1230px) {
  .list-autre .col-xl-3.col-lg-4.col-md-6.col-sm-6 {
    max-width: 25%;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
  .list-autre .col-xl-3.col-lg-4.col-md-6.col-sm-6 {
    max-width: 33.3%;
  }
}

.list-autre .collection {
  padding: 10px 8px 10px;
  border-radius: 10px;
}

.list-autre .collection-thumb img {
  height: 180px;
}

.list-autre .collection-bottom i,
.list-autre .collection-top i {
  font-size: 14px !important;
  margin: 0;
}

.list-autre .collection-bottom .custom-control-label:before {
  width: 16px;
  height: 16px;
}

.list-autre .collection-bottom .custom-control-label:after {
  top: 2px;
}

.list-autre .collection-content p {
  font-size: 14px;
}

.collection-badge {
  position: absolute;
  left: -14px;
  top: -6px;
}

.collection-fav-alb {
  position: absolute;
  left: calc(100% - 7px);
  top: -5px;
}

.list-autre .collection-fav-alb {
  left: calc(100% - 14px);
}

.collection-fav {
  margin-bottom: 10px;
}

.collection-portal {
  margin-top: 10px;
  width: 18px;
}

.collection-portal:hover {
  cursor: pointer;
}

.list-autre .collection-thumb img {
  width: calc(100% - 30px);
}

.list-autre .collection-thumb {
  margin: 0 0 4px 0;
}

.collection-thumb a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.collection-badge-center {
  position: absolute;
  font-size: 28px;
  color: white;
}

.collection-badge-center::before {
  content: '';
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.3);
  position: absolute;
  top: -12px;
  left: -20px;
  border-radius: 50%;
  filter: blur(2px);
  border: 1px solid white;
}

.list-autre .collection .social-bar div {
  margin: 0 1px 0 0;
}

.list-autre .collection .social-bar {
  display: flex;
}

.list-autre .collection .social-bar-drop {
  display: none;
}

.collection-bottom svg {
  width: 22px;
  height: 22px;
}

.list-autre .collection-bottom svg {
  width: 18px;
  height: 18px;
}

.rs-dropdown-menu {
  z-index: 9999 !important;
}


/* gridphoto album new icon */
.collection .icon_albums {
  width: 18px;
  height: 18px;
}

.collection #added-album .icon_albums,
.collection #grey-icon:hover .icon_albums {
  background-color: #007bff;
}

.dark .collection #added-album .icon_albums,
.dark .collection #grey-icon:hover .icon_albums {
  background-color: #4393fb;
}

.collection #grey-icon .icon_albums,
.collection #added-album:hover .icon_albums {
  background-color: #777;
}

.dark .collection #grey-icon .icon_albums,
.dark .collection #added-album:hover .icon_albums {
  background-color: #cbcbcb;
}

.list-autre .collection .icon_albums {
  width: 15px;
  height: 15px;
}

.dark .settings .setinfo {
  color: #999 !important;
}

/* order table overflow */
.settings .rs-table-body-row-wrapper {
  overflow: visible !important;
}

.dark .rs-table,
.dark .rs-table-body-row-wrapper .rs-table-cell {
  background-color: var(--dark) !important;
}

.dark .rs-table-row-header .rs-table-cell-header .rs-table-cell {
  background: var(--darkblue) !important;
  color: white;
  border-bottom: none;
}

.dark .rs-table-row, .dark .rs-table-column-resize-spanner, .dark .rs-table-hover .rs-table-row:not(.rs-table-row-header):hover {
  border-color: var(--dark);
}

.dark .rs-table-row.rs-table-row-header {
  background: var(--darkblue);
}

.dark .rs-table-cell .rs-checkbox-checked {
  background-color: unset;
}

.dark .rs-table-cell {
  border-color: var(--darker);
}

.dark .rs-table-cell-group.rs-table-cell-group-fixed-left .rs-table-cell {
  background: var(--darkmenubkg) !important;
}

.dark .settings .rs-table-scrollbar-handle {
  background-color: var(--darker);
}

.dark .rs-input-group.rs-input-group-inside input {
  background-color: var(--darker);
  border: none;
}

/* Calendar ipnut and buttons */
.inputcalendar input {
  width: 700px;
  margin-right: auto;
}

@media (max-width: 980px) {
  .inputcalendar input {
    width: auto;
  }
}

@media (max-width: 1190px) {
  .inputcalendar input {
    width: 500px;
  }
}

/* Copy Clipboard SB Learning codes */
.sblearningcodes .settitle {
  width: 210px;
}

.sblearningcodes textarea {
  resize: none;
  height: 40px !important;
  font-size: 14px;
  padding: 8px 0 8px 5px;
}

.dark .sblearningcodes textarea {
  background: var(--darker);
  border-color: var(--darkmenubkg);
}

@media (min-width: 1200px) {
  .col-photo {
    position: fixed;
    z-index: 1030;
  }

  .col-photo .imgmod {
    max-width: 48vw;
  }

  .hv-100 .rs-modal-dialog .rs-modal-content {
    height: 89vh;
  }

  .hv-100 .rs-modal-body {
    margin-top: 40px;
  }
}

@media (min-width: 2000px) {
  .col-photo .imgmod {
    max-width: 55vw;

  }
}

@media ((min-width: 1200px) AND (max-height: 1200px)) {
  .col-photo .imgmod {
    max-height: calc(100vh - 430px);
  }
}

.thumb_unknown {
  padding: 10px 10px 10px 5px;
}

.thumb_unknown svg {
  width: 45px;
  height: 45px;
  fill: #4b4b4b;
}

/* Tag ghost for Modal PublishTo*/
.tag-ghost .rs-tag {
  padding: 1px 5px 1px 5px;
  background: transparent;
}

.tag-ghost .rs-tag-red {
  border: 1px solid red;
  color: red;
}

.tag-ghost .rs-tag-orange {
  border: 1px solid orange;
  color: orange;
}

.tag-ghost .rs-tag-green {
  border: 1px solid green;
  color: green;
}

.iconsize .rs-icon {
  font-size: 16px;
}

.close-footer {
  bottom: 0;
  position: absolute;
  right: 0;
}

.breakword {
  word-break: break-all;
}

/* Upload page */
.activate-tool.tool-active,
.not-active {
  display: none;
}

.activate-tool,
.not-active.tool-active {
  display: block;
}


/* Fullscreen image */
.fullscreen-bbox .rs-btn-default {
  background: transparent;
}

.fullscreen-bbox .rs-btn-default:hover {
  color: #575757;
  background-color: #d9d9d9;
  background-image: none;
}

.fullscreen-bbox .rs-icon-search-minus {
  color: white;
  font-size: 16px;
}

.fullscreen-bbox .rs-icon-search-plus {
  color: white;
  font-size: 16px;
}

.fullscreen-bbox .rs-icon-refresh {
  color: white;
  font-size: 16px;
}

.fullscreen-canvas {
  flex: 1 1 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.fullscreen-inner-canvas {
  min-width: 100px;
  min-height: 100px;
  cursor: grab;
  background-size: 50px;
  transform-origin: center center;
}

.fullscreen-header {
  height: 50px;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: rgb(17 17 17) 0px 1px 5px 0px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
}

.fullscreen-container {
  z-index: 50000;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
}


.rs-checkbox-inline {
  margin-left: 0;
}

.dark .leaveadmin svg {
  fill: rgb(248 175 63);
}

ul.rs-sidenav-nav.rs-nav.rs-nav-default.rs-nav-vertical {
  height: 68px;
  margin-bottom: 0;
}

ul.rs-sidenav-nav.rs-nav.rs-nav-default.rs-nav-vertical a {
  top: 12px;
}

.dark .rs-sidenav-subtle .rs-sidenav-item:hover {
  background-color: var(--dark);
}

/* Admin container main fix */
.rs-container main {
  margin: 0;
}

.rs-drawer-body .rs-picker-toggle-placeholder {
  font-size: 0.8rem;
}

.rs-drawer-body .rs-stack-item svg {
  width: 14px;
  height: 14px;
  right: 8px !important;
  top: 10px !important;
}

.rs-drawer-body-close {
  display: none;
}


.gallery_search .rs-picker-caret-icon,
.modal_add_album .rs-picker-caret-icon {
  display: none;
}

.op-50 {
  opacity: 0.5;
}

.op-20 {
  opacity: 0.2;
}

.op-0 {
  opacity: 0;
}

.rs-drawer-header,
.react-tabs__tab-list {
  border-bottom: none;
}



/* Arrow icon missing from rsuite */
i.mr-1.rs-icon.rs-icon-angle-right::after {
  content: ">";
  font-style: normal;
}

.gallery-toggle .rs-btn-secondary {
  background-color: #ececec;
}

.dark .gallery-toggle .rs-btn-secondary {
  background-color: var(--dark);
}


.dark textarea {
  background: var(--darker);
  border: none;
}

.dark .rs-input {
  background-color: var(--darker);
  border-color: var(--darker);
  color: var(--grey);
}

/* Edit tag price */
.dark .rs-input-group {
  border: none;
}

.dark .rs-input-group input {
  background-color: var(--darker);
}

.dark span.rs-input-group-addon {
  background: var(--darkblue);
  color: white;
}

/* Edit drawer buttons effect */
.react-tabs__tab:focus::after {
  background: none !important;
}

.react-tabs__tab-list {
  border-bottom: none;
}


.dark .rs-popover-content {
  background-color: var(--dark);
}

.dark .rs-dropdown-menu {
  background-color: var(--darkblue);
}

.dark .rs-popover[class*=placement-bottom]>.rs-popover-arrow:after {
  border-bottom-color: var(--darkblue);
}

.dark .rs-dropdown-item {
  color: white;
}

#duplicate,
#verified {
  font-size: 12px;
}

.profile-txt-upload {
  top: 78px;
  position: relative;
}

.modal-event-add .rs-input-group {
  width: 180px;
}

.modal-event-add p {
  width: 140px;
}

.dark .rs-toggle-presentation {
  background: var(--darker);
}

.dark .rs-toggle-checked .rs-toggle-presentation {
  background: #2589f5;
}

.iconaddevent {
  font-size: 20px;
}



.albumpplus {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
}



.fixed-search {
  position: fixed;
  top: 56px;
  right: 15px;
  z-index: 100;
  background: white;
  padding: 6px 0 6px 6px;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.04);
}

.fixed-search .mb-3 {
  margin-bottom: 0px !important;
}

.dark .fixed-search {
  background-color: var(--dark);
}

.drop-alb-div {
  cursor: grabbing;
  display: flex;
  justify-content: center;
}

.drop-alb-div .icon_addtocol {
  height: 14px;
}

.card-view-icon {
  cursor: grabbing;
  display: flex;
  justify-content: center;
}

.drop-alb-div .simple-icon-options-vertical::before, .card-view-icon::before {
  padding: 7px;
}

.drop-alb-div .simple-icon-options-vertical:hover::before, .card-view-icon:hover::before {
  background-color: #f4f4f4;
  border-radius: 5px;
}
.dark .drop-alb-div .simple-icon-options-vertical:hover::before, .dark .card-view-icon:hover::before {
  background-color: var(--darker);
}

.drop-alb-div span.d-flex {
  margin-bottom: 10px;
}

.drop-alb-div span>span {
  font-size: 14px;
}

.drop-alb-div .albumpplus {
  font-size: 18px;
}

.drop-alb-div span>span:nth-child(1),
.drop-alb-div span>i:nth-child(1) {
  width: 20px;
  text-align: center;
}

.dropdown-albums {
  position: absolute;
  z-index: 9;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 8px;
  padding: 18px 18px 4px 18px;
  width: 210px;
  font-size: 14px;
}

.dropdown-albums.bottom-end span {
  cursor: pointer;
}

.dropdown-albums .dr-arrow {
  font-size: 10px;
  font-weight: 700;
}

.drop-alb-div .bottom-end {
  top: 95%;
  right: 0;
}

.dark .dropdown-albums {
  background: var(--darkblue);
  color: white;
}

.dark .dropdown-albums button {
  color: white;
}

.sub-menu-dropdown.right-start {
  position: absolute;
  z-index: 9;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 8px;
  padding: 18px;
  width: 220px;
  right: -20px;
  bottom: 44px;
}

.modal-logo img {
  width: 120px;
}

.no-pp-subs {
  opacity: 0.4;
}

.album-owner:first-letter {
  text-transform: uppercase;
}

.albbtnselect {
  width: 104px;
}

@media (min-width: 1200px) {
  .col-xl-2-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

/* Card gridphoto media consent */
.div-consent-dropdown {
  background-color: #fff;
  border-radius: 50% 0 25% 0;
  cursor: help;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 8px;
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 6;
}

.dark .div-consent-dropdown {
  background-color: var(--dark);
}

.consent-badge {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin: auto;
}

.div-consent-dropdown.green .consent-badge {
  border: 4px solid #4caf50;
}

.div-consent-dropdown.red .consent-badge {
  border: 4px solid #f44336;
}

.div-consent-dropdown.yellow .consent-badge {
  border: 4px solid #ffca28;
}

.div-consent-dropdown.orange .consent-badge {
  border: 4px solid #ff6e04;
}

.div-consent-dropdown.blue .consent-badge {
  border: 4px solid #2196f3;
}

.div-consent-dropdown.violet .consent-badge {
  border: 4px solid #673ab7;
}

.div-consent-dropdown.green .consent-type {
  color: #4caf50;
}

.div-consent-dropdown.red .consent-type {
  color: #f44336;
}

.div-consent-dropdown.yellow .consent-type {
  color: #ffca28;
}

.div-consent-dropdown.orange .consent-type {
  color: #ff6e04;
}

.div-consent-dropdown.blue .consent-type {
  color: #2196f3;
}

.div-consent-dropdown.violet .consent-type {
  color: #673ab7;
}

.dropdown-consent {
  position: absolute;
  top: 90%;
  left: 0px;
  z-index: 9;
  float: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 8px;
  padding: 12px 20px 6px 14px;
  width: max-content;
  font-size: 13px;
}

.dark .dropdown-consent {
  background-color: var(--dark);
}


.card.card-bulk.card-curation-green {
  border-color: #4caf50;
}

.card.card-bulk.card-curation-green .albcur {
  color: #4caf50;
}

.card.card-bulk.card-curation-orange {
  border-color: #ff9d04;
}

.card.card-bulk.card-curation-orange .albcur {
  color: #ff9d04;
}

.card.card-bulk.card-curation-red {
  border-color: #ea101a;
}

.card.card-bulk.card-curation-red .albcur {
  color: #ea101a;
}

.card.card-bulk.card-favorite {
  border-color: #ffe404;
}

.pointer {
  cursor: pointer;
}

.radio-collection div {
  margin-right: 12px;
}




.fav-star-trc {
  align-items: center;
  background-color: #fff;
  border-radius: 0 50% 0 10px;
  display: flex;
  flex-direction: column;
  min-height: 30px;
  padding-top: 3px;
  position: absolute;
  right: 8px;
  top: 0;
  width: 30px;
  z-index: 6;

}

.dark .fav-star-trc {
  background-color: var(--dark);
}

.fav-star-trc #my-fav .rs-icon,
.fav-star-trc #grey-icon .rs-icon {
  font-size: 16px;
}

.fav-star-trc #my-fav button,
.fav-star-trc #grey-icon button {
  height: 26px;
  width: 30px;
  padding: 0;
}

.fav-star-trc #my-fav button:hover,
.fav-star-trc #grey-icon button:hover {
  background: none;
}

.fav-star-trc #my-fav button i,
.fav-star-trc #grey-icon button i {
  width: 30px;
  margin: 0;
  padding: 0;
}

.verify-trc {
  height: 26px;
}

.verify-trc i {
  font-size: 12px;
}

.custom-control.custom-checkbox {
  display: flex;
  align-items: flex-start;
}




.div-favalbcol {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: help;
  top: 0px;
  right: 15px;
  background-color: white;
  border-radius: 0 50% 0 25%;
}

.div-favalbcol-dropdown {
  position: absolute;
  top: 90%;
  right: 0px;
  z-index: 9;
  float: left;
  box-shadow: -5px 10px 20px 0px rgba(0, 0, 0, 0.01), -1px 3px 5px 0px rgba(0, 0, 0, 0.08);
  background: white;
  border-radius: 8px;
  padding: 8px 6px 12px 6px;
  width: max-content;
  font-size: 13px;
  align-items: center;
}

.dark .div-favalbcol,
.dark .div-favalbcol-dropdown {
  background-color: var(--dark);
}

.div-favalbcol-dropdown #my-fav .rs-icon,
.div-favalbcol-dropdown #grey-icon .rs-icon {
  font-size: 16px;
}

.collection .div-favalbcol-dropdown .collection-album svg,
.collection .div-favalbcol-dropdown .icon_collections {
  width: 16px;
  height: 16px;
}


.drop-alb-div-abs {
  cursor: grabbing;
  position: absolute;
  right: 14px;
  bottom: 12px;
  display: flex;
  justify-content: center;
}

.drop-alb-div-abs span.d-flex {
  margin-bottom: 10px;
}

.drop-alb-div-abs .simple-icon-options-vertical::before {
  padding: 8px;
}

.drop-alb-div-abs .simple-icon-options-vertical:hover::before {
  background-color: #f4f4f4;
  border-radius: 6px;
}

.dark .drop-alb-div-abs .simple-icon-options-vertical:hover::before {
  background-color: var(--darker);
}

.simple-icon-eye:before hover::before {
  background-color: var(--darker);
}

.drop-alb-div-abs .bottom-end {
  top: 145%;
  right: 0;
}


.dropdown-auto-bottom {
  display: inline;
  position: relative;
}

.dropdown_list {
  list-style: none;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 8px;
  padding: 18px;
  width: max-content;
}
.dark .dropdown_list {
  background-color: var(--darkblue);
} 
.dropdown-auto-bottom:hover .dropdown_list,
.dropdown_list:hover {
  display: block;
  top: 100%;
}




.album-auto-bottom {
  display: inline;
  position: relative;
}

.album_list {
  list-style: none;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9;
  float: left;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
  background: white;
  border-radius: 8px;
  padding: 18px;
  width: max-content;
}
.dark .album_list {
  background-color: var(--darkblue);
}

.album-auto-bottom:hover .album_list,
.album_list:hover {
  display: block;
  top: 100%;
}

.album-auto-bottom .simple-icon-options-vertical::before {
  padding: 8px;
}

.album-auto-bottom .album_list span.d-flex {
  margin-bottom: 10px;
}

.table-sb-details td {
  border: none;
  padding: 0px;
}
.dark .table-sb-details td {
  color: var(--grey);
}

.font-600 {
  font-weight: 600;
}

.bulk-edit-btn {
  font-size: 14px;
}


.icon_addtocol.icon_collections {
  width: 15px;
  height: 15px;
  display: block;
  background-color: #29344a;
}

.icon_addtocol.icon_collections.be-btn-bg-violet {
  background-color: #490b99;
}

.icon_addtocol.icon_albums {
  display: block;
  height: 15px;
  width: 15px;
  background-color: #29344a;
}

.icon_addtocol.icon_albums.be-btn-bg-blue {
  background-color: #007bff;
}

.icon_addtocol.icon_albums.icon-bg-ellipsis {
  background-color: #29344a;
}

.be-btn-green {
  color: green;
}

.be-btn-red {
  color: red;
}

.bar-top-selected {
  min-height: 30px;
}


.icon_addtocol.icon_gallery {
  width: 16px;
  height: 16px;
  display: block;
  background-color: #29344a;
}

.grid-card {
  padding-left: 8px;
  padding-right: 8px;
}


.dark .fav-star-trc #grey-icon .rs-btn,
.dark .fav-star-trc #my-fav .rs-btn {
  background: transparent;
}


.simple-icon-options-vertical.op-0 {
  width: 28.8px;
}


.card-albums {
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 16px;
}

.card-albums .card {
  padding: 12px 14px 8px;
  border-radius: 10px;
}

.card-albums h3 {
  font-size: 15px;
}

.card-albums .albcur,
.card-albums .albfav {
  font-size: 20px;
  height: 20px;
}

.card-mt-head {
  margin-top: 24px;
}

.card-albums .album-owner {
  font-size: 12px;
  opacity: 0.75;
}

.contexify.card-contextify {
  box-shadow: none;
  background: none;
}
.card-contextify span.d-flex {
  margin-bottom: 10px;
}

.border-preventovf #PreventOvfDiv .rs-picker-select-menu::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom: 2px solid #eee;
  z-index: -1;
}


/* Define keyframes for the Download progress bar animation */
@keyframes progressBarAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.zip-progress-container {
  position: relative;
    width: 300px;
    height: 6px;
    background-color: #cfd8dc;
    overflow: hidden;
    border-radius: 4px;
}

.zip-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #7dc443;
  animation: progressBarAnimation 3s ease forwards; /* Apply animation to progress bar */
}
.w-prepare {
  width: 300px;
}

.loading-center {
  position: absolute;
  top: 50%;
}

.modal-create-album .rs-modal-content {
  margin-bottom: 80px;
}
.modal-create-album .rs-modal-body {
  overflow: visible !important;
  max-height: 100% !important;
}
.modal-create-album .rs-picker-select-menu,
.modal-create-album .rs-picker-input-menu,
.modal-create-album .rs-picker-daterange-menu,
.modal-create-album .rs-picker-date-menu {
  position: absolute !important;
}




.lc-div-logo {
  border-radius: 10px 0 0 10px;
  box-shadow: 3px 3px 70px rgba(0, 0, 0, 0.075);
  background-color: white;
}
.dark .lc-div-logo {
  background-color: var(--darker);
  border: 1px solid #5333ed;
}
.lc-div-logo img {
  max-height: 45px;
  max-width: 100%;
  object-fit: contain;
}

.lc-div-button, .dark .lc-div-button {
  background-color: #5333ed;
  color: white;
  border-radius: 0 10px 10px 0;
  padding: 80px;
  box-shadow: 3px 3px 70px rgba(0, 0, 0, 0.075);

}

.lc-div-button .sb_learning {
  background-color: white;
  font-size: 14px;
  border-radius: 7px;
  color: #5333ed;
}
.dark .lc-div-button {
  background-color: #5333ed;
}
.dark #app-container .lc-div-button a.sb_learning {
  background: white;
  color: #5333ed;
}

@media (max-width: 768px) {
  .lc-div-logo {
    padding: 80px;
    border-radius: 10px 10px 0 0;
  }
  .lc-div-button {
    border-radius: 0 0 10px 10px;
  }
}



.lc-div-button .icon_docs {
  width: 20px;
  height: 20px;
  display: block;
  background-color: white;
}